import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { api } from "../api/api";
import { IDemands } from "../interfaces/IDemands";

const getDemands = async <T>(
  page?: number,
  take?: number,
  title?: string,
  order?: string,
  city?: string,
  state?: string
): Promise<IDemands> => {
  let params = new URLSearchParams();
  if (page !== undefined) params.append("page", page?.toString());
  if (take !== undefined) params.append("take", take?.toString());
  if (title !== undefined) params.append("title", title?.toString());
  if (order !== undefined) params.append("order", order?.toString());
  if (city !== undefined) params.append("city", city?.toString());
  if (state !== undefined) params.append("state", state?.toString());

  const { data } = await api.get<IDemands>("/demands", {
    params: params,
  });

  return data;
};

export const useDemands = <T>(
  page?: number,
  take?: number,
  title?: string,
  order?: string,
  city?: string,
  state?: string
): UseQueryResult<IDemands> => {
  return useQuery(["demands", page, take, title, order, city, state], async () => {
    try {
      return await
        getDemands(page, take, title, order, city, state)
    } catch (error) {
      console.error("Erro na consulta de ofertas:", error);
      throw error;
    }
  }
  );
};

export const postDemands = async (data: IDemands) => {
  const response = await api.post("/demands", data);

  return response.data;
};

export const putDemands = async (data: any) => {
  const response = await api.put(`/demands/${data?._id}`, data);

  return response.data;
};

export const deleteDemands = async (id: any) => {
  const response = await api.delete(`/demands/${id?._id}`);

  return response.data;
};
