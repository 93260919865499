import { Global } from "@emotion/react";
import { MantineProvider as Provider } from "@mantine/core";
import { theme } from "./theme";

interface IProps {
  children: React.ReactNode;
}

const MantineProvider: React.FC<IProps> = ({ children }) => {
  return (
    <Provider withGlobalStyles withNormalizeCSS theme={theme}>
      <Global
        styles={(theme) => ({
          body: {
            background: "#FFFFFF",
            margin: "0",
            border: "unset",
            ":before": {
              boxSizing: "unset",
            },
          },
          "::-webkit-scrollbar": {
            width: "12px",
          },
          "::-webkit-scrollbar-track": {
            borderRadius: "16px",
            boxShadow: "inset 0 0 5px grey",
          },
          "::-webkit-scrollbar-thumb": {
            borderRadius: "16px",
            background: "#FFB600",
          },
        })}
      />
      {children}
    </Provider>
  );
};

export default MantineProvider;
