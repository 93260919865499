import {
  Button,
  Flex,
  Pagination,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { DATA_SELECT_ORDER } from "../../../constants/contants";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import ListDemands from "../Demands/ListDemands";
import FormDemands from "./FormDemands";
import { useDemands } from "../../../hooks";
import { DivFather, ListSkeleton, ScreenEmpty } from "../../../components";
import { getCitiesState } from "../../../resources/resource";
import { useAuth } from "../../../context/auth";

const Demands: React.FC = () => {
  const auth = useAuth();
  //const stateFridge = !!auth?.user?.state ? auth?.user?.state : undefined;
  const cityFridge = !!auth?.user?.city ? auth?.user?.city : undefined;
  const [activePage, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string>("latest");
  const [valueCity, setValueCity] = useState<string | undefined | null>(
    undefined
  );
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const { data: demands } = useDemands(
    activePage,
    5,
    search,
    order,
    !!valueCity ? valueCity : undefined
    //stateFridge
  );

  const total = demands?.totalDemands;

  const edit = () => {
    setOpenModalForm(!openModalForm);
  };

  const cities = getCitiesState(auth?.user?.state);

  return (
    <DivFather>
      <FormDemands
        state={cityFridge}
        openModal={openModalForm}
        setOpenModal={() => setOpenModalForm(!openModalForm)}
      />
      <Flex align="center" gap={32} mb={34}>
        <Text fs={"16px"} fw={500} lh={"24px"}>
          Ordens de compra
        </Text>
      </Flex>
      <Flex align={"center"} gap={32} justify={"space-between"} mb={"32px"}>
        <TextInput
          w="100%"
          type="search"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Pesquisar pelo título"
          icon={<IconSearch color="#BFC0C2" size={18} />}
        />
        <Flex gap="32px">
          <Select
            w="100%"
            miw={{ base: 221 }}
            placeholder="Todos"
            rightSection={<IconChevronDown size="1rem" />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={DATA_SELECT_ORDER}
            value={order}
            defaultValue="latest"
            onChange={(e) => setOrder(e ? e : "")}
          />
          {/*    <Select
            searchable
            clearable
            value={valueCity}
            w="100%"
            miw={{ base: 261 }}
            onChange={setValueCity}
            placeholder="Cidade"
            rightSection={!valueCity ? <IconChevronDown size="1rem" /> : null}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={cities}
          /> */}
          <Button
            miw={169}
            my="auto"
            fullWidth
            variant="primary"
            onClick={edit}
          >
            Criar nova demanda
          </Button>
        </Flex>
      </Flex>
      {!demands ? (
        <ListSkeleton repeat={5} />
      ) : !!demands && total === 0 ? (
        <ScreenEmpty text="Nenhuma demanda encontrada" />
      ) : (
        <>
          <ListDemands data={demands} />
          <Flex
            align={"center"}
            justify={"space-between"}
            pt={"71px"}
            mb={"55px"}
            mt="auto"
          >
            <Text bottom={"0px"} color="#BFC0C2">
              {total} demandas cadastradas
            </Text>
            <Pagination
              mt="auto"
              value={activePage}
              onChange={setPage}
              total={!demands?.totalPages ? 1 : demands?.totalPages}
            />
          </Flex>
        </>
      )}
    </DivFather>
  );
};
export default Demands;
