import { Flex, Skeleton } from "@mantine/core";

interface IProps {
  repeat: number;
}

const ListSkeleton: React.FC<IProps> = ({ repeat }) => {
  const lista = [];
  for (let i = 0; i < repeat; i++) {
    lista.push(i);
  }

  return (
    <>
      {lista?.map((index: number) => (
        <Flex key={index} gap={5} align="center" py={{ base: 12 }}>
          <Skeleton visible={true} h={130} />
        </Flex>
      ))}
    </>
  );
};

export default ListSkeleton;
