import { Flex, Pagination, Select, Text } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { DATA_SELECT_ORDER } from "../../../constants/contants";
import { useState } from "react";
import ListProperties from "./ListProperties";
import { useFarms } from "../../../hooks";
import { DivFather, ListSkeleton, ScreenEmpty } from "../../../components";
import { getCitiesState } from "../../../resources/resource";
import { useAuth } from "../../../context/auth";

const PropertyFridge: React.FC = () => {
  const auth = useAuth();
  const stateFridge = !!auth?.user?.state ? auth?.user?.state : undefined;
  const [activePage, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string>("latest");
  const [valueCity, setValueCity] = useState<string | undefined | null>(
    undefined
  );

  const { data: farm } = useFarms(
    undefined,
    activePage,
    6,
    order,
    search,
    !!valueCity ? valueCity : undefined,
    undefined
    //stateFridge
  );

  const cities = getCitiesState(stateFridge);

  const total = farm?.totalFarms;

  return (
    <DivFather>
      <Flex align="center" gap={32} mb={34}>
        <TextInput
          type="search"
          w="100%"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Pesquisar"
          icon={<IconSearch color="#BFC0C2" size={18} />}
        />
        <Flex gap={40} w="auto">
          <Select
            w="100%"
            miw={{ base: 221 }}
            placeholder="Todos"
            rightSection={<IconChevronDown size="1rem" />}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={DATA_SELECT_ORDER}
            value={order}
            defaultValue="latest"
            onChange={(e) => setOrder(e ? e : "")}
          />
          <Select
            searchable
            clearable
            value={valueCity}
            w="100%"
            miw={{ base: 261 }}
            onChange={setValueCity}
            placeholder="Cidade"
            rightSection={!valueCity ? <IconChevronDown size="1rem" /> : null}
            rightSectionWidth={30}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={cities}
          />
        </Flex>
      </Flex>
      {!farm ? (
        <ListSkeleton repeat={4} />
      ) : !!farm && total === 0 ? (
        <ScreenEmpty text="Nenhuma propriedade encontrada" />
      ) : (
        <>
          <ListProperties data={farm} />
          <Flex
            justify="space-between"
            align="center"
            pt={{ base: 41 }}
            mt="auto"
            pb={{ base: 55 }}
          >
            {total !== undefined ? (
              <Text color="gray.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
                {total} Propriedade
                {total > 1 ? "s " : null}
                cadastrada
                {total > 1 ? "s" : null}
              </Text>
            ) : (
              "Nenhuma propriedade"
            )}
            <Pagination
              value={activePage}
              onChange={setPage}
              total={!farm?.totalPages ? 1 : farm?.totalPages}
            />
          </Flex>
        </>
      )}
    </DivFather>
  );
};

export default PropertyFridge;
