import { Button, Center, Flex, Tabs, Text } from "@mantine/core";
import { IconAdjustmentsHorizontal, IconLogout } from "@tabler/icons-react";
import {
  DATA_NAVBAR_ADMIN,
  DATA_NAVBAR_FRIGORIFICO,
  NAV_LINKS,
} from "../../constants/contants";
import { useNavigate, useParams } from "react-router-dom";
import ModalAccess from "../ModalAccess";
import { useLocalStorage } from "@mantine/hooks";

const Navbar: React.FC = () => {
  const [user] = useLocalStorage<any>({
    key: "@App:user",
    getInitialValueInEffect: true,
  });
  const navigate = useNavigate();
  const { tabValue } = useParams();

  return (
    <Center
      w="100%"
      bg="white.0"
      sx={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)" }}
    >
      <Flex
        maw={1680}
        w="100%"
        justify={"space-between"}
        align={"center"}
        mx={{ base: 20 }}
        py={{ base: 24 }}
      >
        <Text
          color="primary.1"
          fz={{ base: 20 }}
          fw={{ base: 600 }}
          lh={{ base: "32px" }}
        >
          Bodetech
          <Text span color="dark.1" fw={{ base: 400 }}>
            {" - "} {user?.role === "Grand Master" ? "Admin" : "Frigorífico"}
          </Text>
        </Text>
        <Tabs
          color="primaryOpacity.3"
          variant="pills"
          defaultValue={"/"}
          value={tabValue}
          onTabChange={(value) => navigate(`${value}`)}
        >
          <Tabs.List >
            {user?.role === "Grand Master"
              ? DATA_NAVBAR_ADMIN?.map((tab: NAV_LINKS) => (
                <Tabs.Tab value={tab.link} key={tab.id}>
                  {tab?.text}
                </Tabs.Tab>
              ))
              : DATA_NAVBAR_FRIGORIFICO?.map((tab: NAV_LINKS) => (
                <Tabs.Tab value={tab.link} key={tab.id}>
                  {tab?.text}
                </Tabs.Tab>
              ))}
          </Tabs.List>
        </Tabs>
        <Flex gap={{ base: 12 }}>
          <ModalAccess
            iconButton={<IconAdjustmentsHorizontal />}
            typeButton="header"
            title="Alterar acesso"
            subTitle="Altere informações de Login e Senha do painel de administrador."
            labelInputLogin="Digite novo email"
            labelInputPassword="Digite nova senha"
            idMap={1}
          />
          <Button
            component="a"
            variant="header"
            leftIcon={<IconLogout />}
            href="/"
            onClick={() => {
              localStorage.removeItem("@App:user");
              localStorage.removeItem("@bodetech:token");
              document.location.reload();
            }}
          >
            Sair
          </Button>
        </Flex>
      </Flex>
    </Center>
  );
};

export default Navbar;
