import { useState } from "react";
import {
  Anchor,
  Avatar,
  Box,
  Flex,
  Text,
  Center,
  useMantineTheme,
} from "@mantine/core";
import { IconAt, IconPhoneCall, IconUser } from "@tabler/icons-react";
import { date } from "../../../../utils/date";
import { ModalOptions, PopoverOptions, Tag } from "../../../../components";
import ModalPropertiesUser from "../ModalPropertiesUser";
import { IUser, IUserData } from "../../../../interfaces/IUser";
import { putUser, useUserById, deleteUser } from "../../../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../constants/queryClients";
import { convertPhone } from "../../../../utils/convert";

interface Iprops {
  data?: IUser;
}

const ListUsers: React.FC<Iprops> = ({ data }) => {
  const theme = useMantineTheme();
  const [itemId, setItemId] = useState<any>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>("");
  const { data: userId } = useUserById(itemId);
  const typeFunc = typeModal === "excluir";

  const { mutate: mutateStatus, isLoading: loadingStatus } = useMutation(
    putUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  const excluir = () => {
    setOpenModal(!openModal);
    setTypeModal("excluir");
  };

  const { mutate: mutateDelete, isLoading: loadingDel } = useMutation(
    deleteUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  const statusChange = () => {
    let obj = Object.assign({
      ...userId,
      blocked: typeModal === "bloquear" ? true : false,
    });
    mutateStatus(obj);
  };

  return (
    <>
      <ModalOptions
        loading={loadingStatus || loadingDel}
        openModal={openModal}
        textStepTwo={"usuário"}
        setOpenModal={() => setOpenModal(!openModal)}
        typeModal={typeModal}
        onClick={typeFunc ? () => mutateDelete(itemId) : statusChange}
      />
      {data?.users?.map((item: IUserData) => (
        <Flex
          key={item?._id}
          align="center"
          gap={{ base: 20, 1420: 50 }}
          py={{ base: 12 }}
          mt={8}
          sx={{ borderBottom: "1px solid", borderColor: theme.colors.gray[1] }}
        >
          <Flex gap={20} w="100%">
            <Center bg="gray.0" sx={{ borderRadius: "4px" }}>
              <Avatar size={116} variant="filled" src={item?.photo}>
                <IconUser color="#707072" size={48} stroke={1.5} />
              </Avatar>
            </Center>
            <Box sx={{ whiteSpace: "nowrap" }}>
              <Text
                fw={500}
                fz={{ base: 14 }}
                lh={{ base: "24px" }}
                color="gray.2"
              >
                {item?.profession}
              </Text>
              <Text
                fz={{ base: 18 }}
                fw={500}
                lh={{ base: "24px" }}
                color="dark.1"
              >
                {item?.name}
              </Text>
              <Anchor
                target="_blank"
                href={`mailto:${item?.email}`}
                color="#2E72E9"
                fw={300}
                fz={{ base: 14 }}
                lh={{ base: "24px" }}
                sx={{
                  svg: {
                    marginBottom: "-3px",
                    marginRight: "8px",
                  },
                }}
              >
                <IconAt size={16} color="#707072" />
                {item?.email}
              </Anchor>
              <Text>
                <Anchor
                  target="_blank"
                  href={`${item?.phone}`}
                  fz={{ base: 14 }}
                  fw={{ base: 300 }}
                  lh={{ base: "24px" }}
                  color="#707072"
                  sx={{
                    svg: {
                      marginBottom: "-3px",
                      marginRight: "8px",
                    },
                  }}
                >
                  <IconPhoneCall size={16} color="#707072" />
                  {!!item?.phone ? convertPhone(item.phone) : "---"}
                </Anchor>
              </Text>
            </Box>
          </Flex>
          <Box w="100%">
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {`${!!item?.city ? item?.city : "--- "}, 
              ${!!item?.state ? item?.state : " -- "
                }`}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Localização
            </Text>
          </Box>
          <Box>
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {item?.farm?.length}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Propriedades
            </Text>
          </Box>
          <Box>
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {date(item?.created_at)}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Cadastro
            </Text>
          </Box>
          <Box w="100%" maw={180}>
            <Text
              tt="capitalize"
              color="dark.1"
              fz={{ base: 18 }}
              lh={{ base: "24px" }}
            >
              {item?.role === "Grand Master" ? "Plano premium pro" : "Normal"}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Assinatura
            </Text>
          </Box>
          <Center>
            <Tag
              w={138}
              status={!item?.blocked ? "green" : "red"}
              title={!item?.blocked ? "Ativo" : "Bloqueado"}
            />
          </Center>
          <ModalPropertiesUser data={item?.farm} phone={item?.phone} />
          <PopoverOptions
            btns={[!item?.blocked ? "Bloquear" : "Desbloquear"]}
            block={item?.blocked ? "false" : "true"}
            setStatus={() => {
              setOpenModal(!openModal);
              setItemId(item?._id);
            }}
            setTypeModal={setTypeModal}
            deleteItem={excluir}
          />
        </Flex>
      ))}
    </>
  );
};

export default ListUsers;
