import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import {
  DATA_ANIMALS,
  DATA_SELECT_ORDER,
} from "../../../../constants/contants";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { BreadCrumbs, DivFather, Tag } from "../../../../components";
import ListHistoryDetails from "./ListHistoryDetails";
import { NavLink, useLocation } from "react-router-dom";

const HistoryDetails: React.FC = () => {
  const {
    state: { animals: objAnimals },
  } = useLocation();
  const [search, setSearch] = useState<string>("");
  const [animals, setAnimais] = useState<any[]>([]);

  const theme = useMantineTheme();
  useEffect(() => {
    if (objAnimals && objAnimals.animals) {
      const lowercasedSearch = search.toLocaleLowerCase();
      const animalsFilter = objAnimals.animals.filter((animal: any) =>
        animal.name.toLocaleLowerCase().includes(lowercasedSearch)
      );
      setAnimais(animalsFilter);
    }
  }, [objAnimals, search]);
  const items = [
    { title: "Histórico", href: "/historico" },
    { title: "Detalhes" },
  ];

  return (
    <>
      <DivFather>
        <BreadCrumbs item={items} />
        <Flex align={"center"} gap={32} justify={"space-between"}>
          <TextInput
            type="search"
            w="100%"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            placeholder="Pesquisar pelo título"
            icon={<IconSearch color="#BFC0C2" size={18} />}
          />
          <Select
            w={"221px"}
            h={"51px"}
            placeholder="Todos"
            data={DATA_SELECT_ORDER}
            rightSection={<IconChevronDown size={"1rem"} />}
          />
          <Tag w={179} h={54} status="gray" ta={"center"} title="Finalizado" />
        </Flex>
        <Flex direction={"column"} justify={"flex-end"} mt={"32px"}>
          <Text fs={"18px"} ff={"poppins"} lh={"24px"} fw={400} color="#BFC0C2">
            {animals?.length} animais
          </Text>
        </Flex>
        <Flex direction={"column"}>
          <ListHistoryDetails data={animals} />
        </Flex>
      </DivFather>
    </>
  );
};
export default HistoryDetails;
