import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { api } from "../api/api";
import { IFridge } from "../interfaces/IFridge";
import { string } from "yup";

const getFridges = async <T>(
  page?: number,
  take?: number,
  order?: "za" | "az" | "latest" | "undefined" | string,
  name?: string,
  city?: string | null,
  state?: string | null
): Promise<IFridge> => {
  let params = new URLSearchParams();

  if (page !== undefined) params.append("page", page.toString());
  if (take !== undefined) params.append("take", take.toString());
  if (order !== undefined) params.append("order", order.toString());
  if (name !== undefined) params.append("name", name.toString());
  if (!!city && city !== undefined) params.append("city", city.toString());
  if (!!state && state !== undefined) params.append("state", state.toString());

  const { data } = await api.get<IFridge>("fridges", {
    params: params,
  });

  return data;
};

export const useFridges = <T>(
  page?: number,
  take?: number,
  order?: "za" | "az" | "latest" | "undefined" | string,
  name?: string,
  city?: string,
  state?: string
): UseQueryResult<IFridge> => {
  return useQuery(["fridges", page, take, order, name, city, state], () =>
    getFridges(page, take, order, name, city, state)
  );
};

export async function getFridgeById(id: string) {
  const { data } = await api.get<any>(`fridges/${id}`);
  return data;
}

export const useFridgeById = <T>(id: string) => {
  return useQuery(["fridges", id], () => getFridgeById(id));
};

export const putFridge = async (data: any) => {
  const resp = await api.put(`/fridges/${data?._id}`, data);
  return resp.data;
};

export const deleteFridge = async (id: any) => {
  const resp = await api.delete(`/fridges${id}`);
  return resp.data;
};
