import { Route, Routes } from "react-router-dom";
import Root from "./Root";
import {
  SignIn,
  ScreenComponents,
  User,
  Property,
  Fridge,
  PageNotFound,
  Demands,
  OfferDetails,
  History,
  HistoryDetails,
  SlaughterRevenue,
  PropertyFridge,
  Offer,
  ResetPassword,
  PurchaseSuccess,
} from "../pages";
import { useAuth } from "../context/auth";
import { useLocalStorage } from "@mantine/hooks";

const Router: React.FC = () => {
  const { signed } = useAuth();
  const [user] = useLocalStorage<any>({
    key: "@App:user",
    getInitialValueInEffect: true,
  });

  return (
    <Routes>
      {signed ? (
        <Route element={<Root />}>
          <Route path="*" element={<PageNotFound />} />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<PurchaseSuccess />} path="/purchase-success" />
          <Route element={<ScreenComponents />} path="/comp" />
          {user?.role === "Grand Master" ? (
            <>
              <Route element={<User />} path="/usuarios" />
              <Route element={<Property />} path="/propriedades" />
              <Route element={<Fridge />} path="/" />
              <Route element={<ResetPassword />} path="/reset-password" />
              <Route element={<PurchaseSuccess />} path="/purchase-success" />
            </>
          ) : (
            <>
              <Route element={<Offer />} path="/demandas/ofertas" />
              <Route element={<PropertyFridge />} path="/propriedades" />

              <Route
                element={<OfferDetails />}
                path="/demandas/ofertas/detalhes-oferta"
              />
              <Route element={<ResetPassword />} path="/reset-password" />
              <Route element={<History />} path="/historico" />
              <Route element={<HistoryDetails />} path="/historico/detalhes" />
              <Route element={<SlaughterRevenue />} path="/receita" />
              <Route element={<Demands />} path="/" />
            </>
          )}
        </Route>
      ) : (
        <Route element={<Root />}>
          <Route element={<SignIn />} path="/" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<PurchaseSuccess />} path="/purchase-success" />
        </Route>
      )}
    </Routes>
  );
};

export default Router;
