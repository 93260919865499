import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Modal,
  Select,
  Text,
  NumberInput,
  useMantineTheme,
  TextInput,
  Checkbox,
  Switch,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar, IconChevronDown } from "@tabler/icons-react";
import "dayjs/locale/pt-br";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaDemands } from "./schema";
import { useMutation } from "@tanstack/react-query";
import { postDemands, useFarms } from "../../../../hooks";
import { ModalSuccess } from "../../../../components";
import { IDemandsData } from "../../../../interfaces/IDemands";
import { CATEGORIES } from "../../../../constants/contants";
import { putDemands } from "../../../../hooks";
import { queryClient } from "../../../../constants/queryClients";
import { useLocalStorage } from "@mantine/hooks";

interface IProps {
  openModal: boolean;
  setOpenModal: () => void;
  item?: any;
  state?: string;
}

const FormDemands: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  item,
  state,
}) => {
  const [user] = useLocalStorage<any>({
    key: "@App:user",
    getInitialValueInEffect: true,
  });

  const theme = useMantineTheme();
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
  const [oneFarm, setOneFarm] = useState<boolean>(true);

  const { data: farmsData } = useFarms(undefined, undefined, 99999, "az");
  const farms = farmsData?.farms || [];

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<IDemandsData>({
    mode: "onBlur",
    resolver: yupResolver(SchemaDemands(oneFarm)),
  });

  const { mutate: create, isLoading: isCreating } = useMutation(postDemands, {
    onSuccess: () => {
      queryClient.invalidateQueries(["demands"]);
      setOpenModalSuccess(true);
      setOpenModal();
    },
    onError: (error: any) => {
      if (!!error) {
        return console.log("Erro ao cadastrar demanda.");
      }
    },
  });

  const { mutate: edit, isLoading: isEditing } = useMutation(putDemands, {
    onSuccess: () => {
      queryClient.invalidateQueries(["demands"]);
      setOpenModalSuccess(true);
      setOpenModal();
    },
    onError: (error: any) => {
      if (!!error) {
        return console.log("Erro ao editar demanda.");
      }
    },
  });

  const onSubmit = (values: any) => {
    console.log("TCL: onSubmit -> values", values);
    if (item) {
      edit({
        _id: item?._id,
        ...values,
      });
    } else {
      create({
        title: user?.name,
        city: state,
        ...values,
      });
    }
  };

  useEffect(() => {
    if (!openModal) {
      reset();
    } else {
      setOneFarm(true);
    }
  }, [openModal]);

  useEffect(() => {
    setValue("farm", undefined);
  }, [oneFarm]);

  useEffect(() => {
    if (!item) return;
    Object.keys(item).map((keys) => {
      let key = keys as keyof unknown;
      setValue(key as any, item[key] as any);
    });
  }, [item, setValue]);

  const brlParser = (value: string) => {
    return value.replace(/[^\d.,]/g, "");
  };

  const brlFormatter = (value: string) => {
    const parts = value.split(",");
    const integerPart = parts[0].replace(/[^\d]/g, "");
    const decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, "") : "";
    let formattedValue = "";

    if (integerPart.length > 0) {
      formattedValue =
        "R$ " + integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    if (decimalPart.length > 0) {
      formattedValue += "," + decimalPart;
    }

    return formattedValue;
  };

  return (
    <>
      <ModalSuccess
        openModal={openModalSuccess}
        setOpenModal={() => setOpenModalSuccess(false)}
        typeModal={item ? "editada" : "criada"}
      />
      <Modal
        size={"auto"}
        centered
        opened={openModal}
        onClose={setOpenModal}
        radius={"16px"}
        closeOnClickOutside={false}
        styles={{
          body: { padding: 0 },
          header: {
            padding: 0,
          },
          close: {
            right: "48px",
            top: "55px",
            position: "absolute",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex
            w={"100%"}
            h={"100%"}
            maw="648px"
            direction={"column"}
            justify={"space-between"}
            p={"48px"}
          >
            <Text
              fz={{ base: 24 }}
              fw={600}
              lh={{ base: "32px" }}
              color="primary.1"
              pr={71}
            >
              Frigorífico
              <Text
                span
                mt={16}
                fz={{ base: 24 }}
                lh={{ base: "32px" }}
                color="dark.1"
              >
                {" - "}
                {item ? "Editar demanda" : "Criar demanda"}
              </Text>
            </Text>
            <Text mt={"16px"} ff={"Poppins"} color="#707072">
              Digite as informações da demanda de animais.
            </Text>
            <Flex
              direction={"column"}
              mt={"32px"}
              align={"flex-start"}
              justify={"flex-start"}
              gap="32px"
            >
              <Flex direction="column" w="100%" mt={"-8px"}>
                <Switch
                  checked={oneFarm}
                  onChange={() => {
                    setOneFarm(!oneFarm);
                  }}
                  styles={{
                    body: {
                      justifyContent: "space-between",
                    },
                    label: {},
                  }}
                  labelPosition="left"
                  label="Criar demanda para todas fazendas"
                  color="yellow"
                />
                {!oneFarm && (
                  <Controller
                    name="farm"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        required
                        clearable
                        searchable
                        mt={"8px"}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        label="Escolha uma fazenda"
                        w={"100%"}
                        error={errors?.farm?.message}
                        placeholder={"Selecione..."}
                        rightSection={
                          !watch("farm") && <IconChevronDown size={"14px"} />
                        }
                        rightSectionWidth={30}
                        data={farms.map((farm: any, index: number) => ({
                          key: index,
                          name: farm?.name,
                          label: farm?.name,
                          value: farm?._id,
                        }))}
                        styles={{
                          label: {
                            marginBottom: "4px",
                            fontSize: "18px",
                            fontWeight: 500,
                            lineHeight: "32px",
                            color: theme.colors.dark[1],
                          },
                          rightSection: {
                            pointerEvents: "none",
                          },
                          input: {
                            border: "1px solid",
                            borderColor: theme.colors.primary[1] + "!important",
                            borderRadius: "4px",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Flex>
              <Controller
                name="quantity_of_animals"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <NumberInput
                    required
                    min={1}
                    type="number"
                    label="Quantidade de animais"
                    w={"100%"}
                    placeholder="Digite..."
                    hideControls
                    error={errors?.quantity_of_animals?.message}
                    //@ts-ignore
                    value={value ? value : ""}
                    onBlur={onBlur}
                    onChange={(value) => onChange(!!value ? value : 0)}
                  />
                )}
              />
              {/* </Flex> */}
              <Flex justify={"space-between"} gap="24px">
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      min={1}
                      miw={{ base: 241 }}
                      label="Categoria"
                      w={"100%"}
                      placeholder={"Selecione..."}
                      rightSection={<IconChevronDown size={"14px"} />}
                      rightSectionWidth={30}
                      data={CATEGORIES}
                      //@ts-ignore
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      error={errors?.category?.message}
                      styles={{
                        label: {
                          marginBottom: "4px",
                          fontSize: "18px",
                          fontWeight: 500,
                          lineHeight: "32px",
                          color: theme.colors.dark[1],
                        },
                        rightSection: {
                          pointerEvents: "none",
                        },
                        input: {
                          border: "1px solid",
                          borderColor: theme.colors.primary[1] + "!important",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="value"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <NumberInput
                      label="Valor por kg"
                      w={"100%"}
                      placeholder="Digite..."
                      onBlur={onBlur}
                      required
                      hideControls
                      decimalSeparator=","
                      thousandsSeparator="."
                      value={value}
                      onChange={(value) => onChange(value)}
                      parser={brlParser}
                      formatter={brlFormatter}
                      precision={2}
                      step={0.01}
                      error={errors?.value?.message}
                    />
                  )}
                />
              </Flex>
              <Flex justify={"space-between"} gap="24px" w="100%">
                <Controller
                  name="opening"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePickerInput
                      label="Abertura"
                      onBlur={onBlur}
                      required
                      size="md"
                      w={"100%"}
                      placeholder={"dd/mm/aa"}
                      color={"#303030"}
                      icon={<IconCalendar color="#FFB600" />}
                      locale="pt-br"
                      valueFormat="DD/MM/YY"
                      weekdayFormat={"ddd"}
                      onChange={(e) => {
                        onChange(e?.toISOString());
                      }}
                      defaultValue={item ? new Date(item?.opening) : null}
                      error={errors?.opening?.message}
                    />
                  )}
                />
                <Controller
                  name="closing"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <DatePickerInput
                      onBlur={onBlur}
                      label="Encerramento"
                      w={"100%"}
                      size="md"
                      placeholder={"dd/mm/aa"}
                      icon={<IconCalendar color="#FFB600" />}
                      locale="pt-br"
                      valueFormat="DD/MM/YY"
                      weekdayFormat={"ddd"}
                      onChange={(e) => {
                        onChange(e?.toISOString());
                      }}
                      defaultValue={item ? new Date(item?.closing) : null}
                      required
                      minDate={new Date(watch("opening"))}
                      maxDate={new Date(5000, 1, 1)}
                      error={errors?.closing?.message}
                    />
                  )}
                />
              </Flex>
            </Flex>
            <Button
              variant="primary"
              mt={"60px"}
              type="submit"
              loading={item ? isEditing : isCreating}
              disabled={
                !isDirty ||
                !isValid ||
                (watch("farm") === undefined && !oneFarm)
              }
            >
              {item ? "Editar demanda" : "Criar demanda"}
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};
export default FormDemands;
