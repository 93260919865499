import { Button, Flex, Modal, Text, useMantineTheme } from "@mantine/core";
import { IconCircleCheck, IconExclamationCircle } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  typeModal: string;
  openModal: boolean;
  setOpenModal: () => void;
  loading?: boolean;
  onClick?: any;
  textStepTwo?: any;
}

const ModalOPtions: React.FC<IProps> = ({
  loading,
  openModal,
  setOpenModal,
  typeModal,
  onClick,
  textStepTwo,
}) => {
  const theme = useMantineTheme();
  const location = useLocation();
  const [step, setStep] = useState<boolean>(true);

  const handleCloseModal = () => {
    setStep(true);
    setOpenModal();
  };

  console.log("TCL: step", step);
  return (
    <Modal
      opened={openModal}
      onClose={setOpenModal}
      centered
      size="auto"
      closeOnClickOutside={false}
      withCloseButton={false}
      styles={{
        body: {
          padding: 32,
        },
        content: {
          borderRadius: 8,
          border: "1px solid",
          borderColor: theme.colors.gray[1],
        },
      }}
    >
      <Flex direction="column" align="center" gap={24} w={{ base: 438 }}>
        {step ? (
          <IconExclamationCircle
            color={theme.colors?.alerts[1]}
            size={88}
            stroke={0.5}
          />
        ) : (
          <IconCircleCheck
            color={theme.colors?.alerts[2]}
            size={88}
            stroke={0.5}
          />
        )}
        <Flex direction="column" align={"center"} gap={4}>
          <Text fz={{ base: 20 }} fw={600} lh={{ base: "150%" }} color="dark.1">
            {step ? "Atenção" : "Concluído"}!
          </Text>
          <Text fz={{ base: 16 }} lh={{ base: "150%" }} color="gray.2">
            {step ? (
              <Text>{` Deseja mesmo ${typeModal} ${textStepTwo} ?`}</Text>
            ) : (
              <Text>
                <Text span tt="capitalize">
                  {textStepTwo}
                </Text>
                {typeModal === "excluir"
                  ? `${
                      location.pathname === "/usuarios"
                        ? " excluido "
                        : " excluida "
                    }`
                  : typeModal === "bloquear"
                  ? `${
                      location.pathname === "/usuarios"
                        ? " bloqueado "
                        : " bloqueada "
                    }`
                  : `${
                      location.pathname === "/usuarios"
                        ? " desbloqueado "
                        : " desbloqueada "
                    }`}
                com sucesso.
              </Text>
            )}
          </Text>
        </Flex>
        {step ? (
          <Flex gap={12} w="100%">
            <Button
              variant={
                typeModal === "desbloquear" || typeModal === "aceitar"
                  ? "primaryOutline"
                  : "primary"
              }
              onClick={setOpenModal}
              fullWidth
            >
              Não, cancelar
            </Button>
            <Button
              variant={
                typeModal === "desbloquear" || typeModal === "aceitar"
                  ? "primary"
                  : "danger"
              }
              onClick={() => {
                onClick();
                setStep(false);
              }}
              fullWidth
              loading={loading}
            >
              Sim, {typeModal}
            </Button>
          </Flex>
        ) : (
          <Button
            variant="primary"
            fullWidth
            onClick={handleCloseModal}
            loaderPosition="right"
            loading={loading}
          >
            Fechar
          </Button>
        )}
      </Flex>
    </Modal>
  );
};

export default ModalOPtions;
