import { ActionIcon, Button, Popover, useMantineTheme } from "@mantine/core";
import { IconEdit, IconLock } from "@tabler/icons-react";
import {
  IconDots,
  IconLockOpen,
  IconTrash,
  IconLinkOff,
  IconBan,
} from "@tabler/icons-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  block?: string;
  setStatus: () => void;
  deleteItem: () => void;
  editItem?: any;
  disabled?: boolean;
  setTypeModal?: any;
  btns: string[];
}

const PopoverOptions: React.FC<IProps> = ({
  setStatus,
  deleteItem,
  editItem,
  disabled,
  setTypeModal,
  btns,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  return (
    <>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={247}
        trapFocus
        position="bottom-end"
        withArrow
        shadow="md"
        styles={{
          arrow: {
            right: "18px !important",
            borderColor: "#D9D9D9",
          },
          dropdown: {
            borderColor: "#D9D9D9",
          },
        }}
      >
        <Popover.Target>
          <ActionIcon
            disabled={disabled}
            variant="transparent"
            size={"48px"}
            onClick={() => {
              setOpened((o) => !o);
            }}
          >
            <IconDots
              size="36px"
              color={disabled ? theme.colors.gray[1] : theme.colors.dark[1]}
            />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          {location.pathname === "/" && (
            <Button
              onClick={() => {
                editItem();
                //   setStatus();
                setTypeModal("editar");
                setOpened((o) => !o);
              }}
              fullWidth
              leftIcon={<IconEdit />}
              variant="options"
            >
              Editar demanda
            </Button>
          )}

          {btns.some((e: any) => e === "Desvincular") ? (
            <Button
              onClick={() => {
                setStatus();
                setOpened((o) => !o);
                setTypeModal("Desvincular");
              }}
              fullWidth
              variant="options"
              c="dark.0"
              leftIcon={<IconLinkOff />}
            >
              Desvincular
            </Button>
          ) : null}

          {btns.some((e: any) => e === "Desbloquear") ? (
            <Button
              onClick={() => {
                setStatus();
                setOpened((o) => !o);
                setTypeModal("desbloquear");
              }}
              fullWidth
              variant="options"
              c="alerts.2"
              leftIcon={<IconLockOpen />}
            >
              Desbloquear
            </Button>
          ) : null}
          {btns.some((e: any) => e === "Suspender") ? (
            <Button
              onClick={() => {
                setStatus();
                setOpened((o) => !o);
                setTypeModal("suspender");
              }}
              fullWidth
              variant="options"
              c="alerts.1"
              leftIcon={<IconBan />}
            >
              Suspender
            </Button>
          ) : null}
          {btns.some((e: any) => e === "Bloquear") ? (
            <Button
              onClick={() => {
                setStatus();
                setOpened((o) => !o);
                setTypeModal("bloquear");
              }}
              fullWidth
              variant="options"
              c="alerts.1"
              leftIcon={<IconLock />}
            >
              {location.pathname === "/" ? "Suspender" : "Bloquear"}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              deleteItem();
              setOpened((o) => !o);
            }}
            fullWidth
            variant="options"
            c="alerts.0"
            leftIcon={<IconTrash />}
          >
            Excluir
          </Button>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
export default PopoverOptions;
