import { Modal, useMantineTheme, Flex, Text, Button } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { type } from "os";
import React, { useState } from "react";

interface IProps {
  openModal: boolean;
  setOpenModal: () => void;
  typeModal?: string;
}

const ModalSuccess: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  typeModal,
}) => {
  const theme = useMantineTheme();
  return (
    <Modal
      size={"auto"}
      withCloseButton={false}
      centered
      opened={openModal}
      onClose={setOpenModal}
      styles={{
        body: {
          padding: 32,
        },
        content: {
          borderRadius: 8,
          border: "1px solid",
          borderColor: theme.colors.gray[1],
        },
      }}
    >
      <Flex direction="column" align="center" gap={24} w={{ base: 438 }}>
        <IconCircleCheck
          color={theme.colors?.alerts[2]}
          size={88}
          stroke={0.5}
        />
        <Flex direction="column" align={"center"} gap={4}>
          <Text fz={{ base: 20 }} fw={600} lh={{ base: "150%" }} color="dark.1">
            Concluído!
          </Text>
          <Text fz={{ base: 16 }} lh={{ base: "150%" }} color="gray.2">
            Demanda {!typeModal ? "criada" : typeModal} com sucesso!
          </Text>
        </Flex>
        <Button variant="primary" fullWidth onClick={setOpenModal}>
          Fechar
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModalSuccess;
