import * as yup from "yup";

export const schemaLogin = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Informe um e-mail válido")
      .required("Informe seu e-mail"),
    password: yup
      .string()
      .min(8, "Senha deve ser conter no mínimo 8 dígitos!")
      .required("Campo é obrigatório"),
  })
  .required();
