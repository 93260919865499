import {
  ActionIcon,
  Anchor,
  Box,
  Center,
  Flex,
  Modal,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconAt,
  IconBuildingCottage,
  IconPhoneCall,
} from "@tabler/icons-react";
import { convertPhone } from "../../../../utils/convert";
import { Tag } from "../../../../components";
import { IFarmData } from "../../../../interfaces/IFarm";

interface Iprops {
  data?: IFarmData[];
  phone?: string;
}

const ModalPropertiesUser: React.FC<Iprops> = ({ data, phone }) => {
  const theme = useMantineTheme();
  const lengthNull = data?.length === 0;
  const [opened, { open, close }] = useDisclosure(false);
  const isLargerThan1274 = useMediaQuery("(min-width:1274px)");
  return (
    <>
      <Modal
        centered
        size={isLargerThan1274 ? "70%" : "100%"}
        mah="50px"
        opened={opened}
        onClose={close}
        title="Propriedades"
        scrollAreaComponent={ScrollArea.Autosize}
        styles={{
          title: {
            fontWeight: 500,
            fontSize: "22px",
            lineHeight: "24px",
            color: theme.colors.dark[1],
          },
          body: { padding: "24px", maxHeight: "512px" },
          header: {
            padding: "26px 24px 0",
          },
        }}
      >
        {data?.map((property) => (
          <Flex
            w="100%"
            key={property?._id}
            align="center"
            justify="space-between"
            py={{ base: 12 }}
            mt={8}
            sx={{
              borderBottom: "1px solid",
              borderColor: theme.colors.gray[1],
              ":last-child": {
                borderBottom: "none",
              },
            }}
            gap={60}
          >
            <Box>
              <Text
                fz={{ base: 18 }}
                fw={500}
                lh={{ base: "24px" }}
                color="dark.1"
              >
                {property?.name}
              </Text>
              <Anchor
                target="_blank"
                href={`mailto:${property?.email}`}
                color="#2E72E9"
                fw={300}
                fz={{ base: 14 }}
                lh={{ base: "24px" }}
                sx={{
                  svg: {
                    marginBottom: "-3px",
                    marginRight: "8px",
                  },
                }}
              >
                <IconAt size={16} color="#707072" />
                {property?.email}
              </Anchor>
              <Text>
                <Anchor
                  target="_blank"
                  href={`+55${property?.phone}`}
                  fz={{ base: 14 }}
                  fw={{ base: 300 }}
                  lh={{ base: "24px" }}
                  color="#707072"
                  sx={{
                    svg: {
                      marginBottom: "-3px",
                      marginRight: "8px",
                    },
                  }}
                >
                  <IconPhoneCall size={16} color="#707072" />
                  {phone}
                </Anchor>
              </Text>
            </Box>
            <Box>
              <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
                {`${!!property?.address ? property?.address : "--- "}, ${
                  !!property?.city?.name ? property?.city?.name : "--- "
                },${
                  !!property?.state?.abbreviation
                    ? property?.state?.abbreviation
                    : " -- "
                }
                `}
              </Text>
              <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
                Localização
              </Text>
            </Box>
            <Box mr={50}>
              <Tag
                h={52}
                sx={{
                  span: {
                    padding: "6px 12px",
                    whiteSpace: "normal",
                    textAlign: "center",
                  },
                }}
                w={158}
                status={
                  property.qualified === "true"
                    ? "green"
                    : property.qualified === "false"
                    ? "red"
                    : "gray"
                }
                title={
                  property.qualified === "true"
                    ? "Região qualificada"
                    : property.qualified === "blocked"
                    ? "Região não qualificada"
                    : "Região bloqueada"
                }
              />
            </Box>
          </Flex>
        ))}
      </Modal>

      <Center>
        <ActionIcon variant="transparent" size={"48px"} disabled={lengthNull}>
          <IconBuildingCottage
            onClick={open}
            size="36px"
            color={lengthNull ? theme.colors.gray[2] : theme.colors.primary[1]}
          />
        </ActionIcon>
      </Center>
    </>
  );
};

export default ModalPropertiesUser;
