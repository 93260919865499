import moment from "moment";
import "moment/locale/pt-br";

export function dateTime(date: string | number | Date) {
  return moment(date).utc(true).format("DD/MM/YYYY - HH:mm");
}

export function date(date: string | number | Date) {
  return moment(date).utc(true).format("DD/MM/YYYY");
}

export function twoDates(one: Date, two: Date) {
  const dateOne = new Date(one)
  const dateTwo = new Date(two)
  if (dateOne.getUTCFullYear() === dateTwo.getFullYear()) {
    return moment(one).utc(true).format("DD/MM") + " - " + moment(two).utc(true).format("DD/MM/YYYY");
  }
  return moment(one).utc(true).format("DD/MM/YYYY") + " - " + moment(two).utc(true).format("DD/MM/YYYY");
}

export function dateInverse(date: string) {
  const auxDate = date.split('/');
  if (auxDate.length === 3 && auxDate[0].length === 4) {
    let dataInvertida = auxDate[2] + '/' + auxDate[1] + '/' + auxDate[0];
    return dataInvertida
  } else {
    return date
  }
}

export function time(date: string | number | Date) {
  return moment(date).utc(true).format("HH:mm");
}

export function timeWithSeconds(date: string | number | Date) {
  return moment(date).utc(true).format("HH:mm:ss");
}

export function calculateWaitingTime(date: string | number | Date) {
  return moment(date).utc(true).format("HH:mm");
}

export function day(date: string | number | Date) {
  return moment(date).utc(true).format("DD");
}

export function calculateWaitingTimeMinutes(date: string | number | Date) {
  if (date) {
    const now = moment(new Date());
    const minutes = moment.duration(now.diff(date)).as("minutes");
    return minutes?.toFixed(0);
  }
  return "0 ";
}
