import { ActionIcon, Button, Popover, useMantineTheme } from "@mantine/core";
import { IconCheck, IconCircleHalf, IconScale } from "@tabler/icons-react";
import { IconDots, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

interface IProps {
  block?: boolean;
  funcBlock: () => void;
  funcUnblock: () => void;
  funcDestroy: () => void;
  funcEditValue: () => void;
}

const PopoverOffer: React.FC<IProps> = ({
  block,
  funcBlock,
  funcDestroy,
  funcUnblock,
  funcEditValue,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Popover
        opened={opened}
        onChange={setOpened}
        width={247}
        trapFocus
        position="bottom-end"
        withArrow
        shadow="md"
        styles={{
          arrow: {
            right: "18px !important",
            borderColor: "#D9D9D9",
          },
          dropdown: {
            borderColor: "#D9D9D9",
          },
        }}
      >
        <Popover.Target>
          <ActionIcon
            variant="transparent"
            size={"48px"}
            onClick={() => setOpened((o) => !o)}
          >
            <IconDots size="36px" color={theme.colors.dark[1]} />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          {block ? (
            <>
              <Button
                onClick={() => {
                  funcEditValue();
                  setOpened((o) => !o);
                }}
                fullWidth
                variant="options"
                c="alerts.2"
                leftIcon={<IconScale />}
              >
                Informar peso
              </Button>
              <Button
                onClick={() => {
                  funcBlock();
                  setOpened((o) => !o);
                }}
                fullWidth
                variant="options"
                c="alerts.1"
                leftIcon={<IconCircleHalf style={{ rotate: "45deg" }} />}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                funcUnblock();
                setOpened((o) => !o);
              }}
              fullWidth
              variant="options"
              c="alerts.2"
              leftIcon={<IconCheck />}
            >
              Aceitar oferta
            </Button>
          )}
          <Button
            onClick={() => {
              funcDestroy();
              setOpened((o) => !o);
            }}
            fullWidth
            variant="options"
            c="alerts.0"
            leftIcon={<IconTrash />}
          >
            Excluir
          </Button>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
export default PopoverOffer;
