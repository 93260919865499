import * as Yup from "yup";

export const SchemaDemands = (oneFarm: boolean) => {
  return Yup.object().shape({
    farm: Yup.lazy(() => {
      return oneFarm
        ? Yup.string().nullable().notRequired()
        : Yup.string().nullable().required("Escolha uma fazenda");
    }),
    quantity_of_animals: Yup.number()
      .required("Quantidade de animais é obrigatória")
      .min(1, "Quantidade mínima é 1"),
    category: Yup.string().required("Categoria é obrigatória"),
    value: Yup.number()
      .required("Valor por kg é obrigatório")
      .min(0, "O valor deve ser maior ou igual a 0"),
    opening: Yup.date().required("Data de abertura é obrigatória"),
    closing: Yup.date()
      .required("Data de encerramento é obrigatória")
      .min(Yup.ref('opening'), "Data de encerramento deve ser após a data de abertura"),
  });
};
