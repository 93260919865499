import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { api } from "../api/api";
//import { IFarm } from "../interfaces/IFarm";

const getFarms = async <T>(
  realm_id?: string,
  page?: number,
  take?: number,
  order?: "za" | "az" | "latest" | "undefined" | string,
  name?: string,
  city?: string | null,
  state?: string | null
): Promise<any> => {
  let params = new URLSearchParams();
  if (realm_id !== undefined) params.append("realm_id", realm_id?.toString());
  if (page !== undefined) params.append("page", page?.toString());
  if (take !== undefined) params.append("take", take?.toString());
  if (order !== undefined) params.append("order", order?.toString());
  if (name !== undefined) params.append("name", name?.toString());
  if (!!city && city !== undefined) params.append("city", city?.toString());
  if (!!state && state !== undefined) params.append("state", state?.toString());

  const { data } = await api.get<any>("/farms", {
    params: params,
  });

  return data;
};

export const useFarms = <T>(
  realm_id?: string,
  page?: number,
  take?: number,
  order?: "za" | "az" | "latest" | "undefined" | string,
  name?: string,
  city?: string,
  state?: string
): UseQueryResult<any> => {
  return useQuery(
    ["farms", realm_id, page, take, order, name, city, state],
    () => getFarms(realm_id, page, take, order, name, city, state)
  );
};

export async function getFarmById(id: string) {
  const { data } = await api.get<any>(`farms/${id}`);
  return data;
}

export const useFarmByid = <T>(id: any) => {
  return useQuery(["farms", id], () => getFarmById(id));
};

export const putFarm = async (data: any) => {
  const resp = await api.put(`/farms/${data?._id}`, data);
  return resp.data;
};

export const deleteFarm = async (id: string) => {
  const resp = await api.delete(`/farms/${id}`);
  return resp.data;
};
