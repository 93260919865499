import React, { createContext, useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../constants/queryClients";
import { api } from "../api/api";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Modal, Text } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";

type FormData = {
  username: string;
  password: string;
};

interface IProfession {
  _id?: string;
  profession?: string;
}

interface ILogin {
  _id?: string;
  name?: string;
  email: string;
  phone?: string;
  role?: string;
  password: string;
  profession?: IProfession[];
  state?: null;
  city?: null;
  blocked?: false;
  token?: string;
}

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  signed: boolean;
  postAuthorize: any;
  user: ILogin | null;
  logout: any;
}

const Context = createContext<IContext>({} as IContext);

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  const [user, setUser, removeUser] = useLocalStorage<ILogin | null>({
    key: "@App:user",
    getInitialValueInEffect: true,
  });

  const [token] = useLocalStorage<string>({
    key: "@bodetech:token",
    getInitialValueInEffect: true,
  });

  const useAuthorize = async (data: FormData) => {
    const response = await api.post("/authorize", data);
    return response.data;
  };

  const logout = () => {
    setUser(null);
    window.localStorage.removeItem("@bodetech:token");
    removeUser();
    window.location.replace("/");
  };

  const { mutate: postAuthorize } = useMutation(useAuthorize, {
    onSuccess: (resp: any) => {
      setUser(resp?.user);
      window.location.href = "/";
      window.localStorage.setItem("@bodetech:token", resp.token);
      queryClient.invalidateQueries(["myUser"]);
    },
    onError: (error: any) => {
      if (!!error) {
        return open();
      }
    },
  });

  return (
    <>
      <Modal
        size={"auto"}
        opened={opened}
        withCloseButton={false}
        onClose={close}
        closeOnClickOutside={false}
        centered
        styles={{
          content: {
            background: "#F8F9FA",
            borderRadius: "16px",
          },
        }}
      >
        <Flex
          direction="column"
          align="center"
          pt={32}
          p={16}
          gap={24}
          w={{ base: 438 }}
        >
          <IconExclamationCircle color={"red"} size={88} stroke={0.5} />
          <Text fz={{ base: 20 }} fw={600} lh={{ base: "150%" }} color="dark.1">
            Login ou senha inválida!
          </Text>
          <Button variant="primary" onClick={close} fullWidth>
            Voltar
          </Button>
        </Flex>
      </Modal>
      <Context.Provider
        value={{ signed: Boolean(token), user, postAuthorize, logout }}
      >
        {children}
      </Context.Provider>
    </>
  );
};

export function useAuth() {
  const context = useContext(Context);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export default Context;
