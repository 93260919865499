import { Flex } from "@mantine/core";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../components";

const Root: React.FC = () => {
  const location = useLocation();
  const storedUser = localStorage.getItem("@App:user");
  const reset = location.pathname === "/reset-password";
  const purchase = location.pathname === "/purchase-success";

  return (
    <Flex h="100%" direction="column" justify="space-between" mih="100vh">
      <Flex direction="column">
        {!!storedUser && !reset && !purchase ? <Navbar /> : ""}
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default Root;
