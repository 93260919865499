import React, { useState } from 'react'
import * as Realm from "realm-web";
import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Flex,
  Modal,
  PasswordInput,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { BackgroundLogin } from "../../assets";
import { PasswordRequirements } from '../../components';
import { useDisclosure } from '@mantine/hooks';
import { IconCircleCheck, IconExclamationCircle } from '@tabler/icons-react';

const ResetPassword: React.FC = () => {
  const app = new Realm.App({ id: "bode-tech-nspvw" });
  const [password, setPassword] = useState("")
  const [passwordTwo, setPasswordTwo] = useState("")
  const [opened, { open, close }] = useDisclosure(false);
  const [success, setSucess] = useState(false)
  const theme = useMantineTheme();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const tokenId = urlParams.get('tokenId');

  const performPasswordReset = async () => {
    try {
      await app.emailPasswordAuth.resetPassword({
        token: !!token ? token : "",
        tokenId: !!tokenId ? tokenId : "",
        password: password,

      });
      open();
      setSucess(false)
    } catch (error) {
      open();
      setSucess(true)
    }
  };

  const isPassword = password === passwordTwo ? false : true
  const lengthPassword = password.length
  const lengthPasswordTwo = passwordTwo.length

  return (
    <BackgroundImage src={BackgroundLogin} opacity="0.8">
      <Center bg="white" mih="100vh">
        <Flex
          m={20}
          w="100%"
          maw={540}
          bg="white.1"
          direction="column"
          p={{ base: 20, sm: 48 }}
          gap={{ base: 16 }}
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box>
            <Text
              fz={{ base: 24 }}
              fw={600}
              lh={{ base: "32px" }}
              color="primary.1"
            >
              BodeTech
            </Text>
            <Text
              mt={16}
              fz={{ base: 16 }}
              lh={{ base: "32px" }}
              color="gray.2"
            >
              Informe sua nova senha
            </Text>
          </Box>
          <PasswordInput
            label="Nova senha"
            withAsterisk
            placeholder="Senha"
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput

            error={isPassword && lengthPassword < lengthPasswordTwo ? "Senhas não conferem" : ""}
            label="Confirmar nova senha"
            withAsterisk
            placeholder="Nova senha"
            onChange={(e) => setPasswordTwo(e.target.value)}
          />
          <PasswordRequirements
            passwordOne={password}
            passwordTwo={passwordTwo}
          />

          <Button
            onClick={performPasswordReset}
            variant="primary"
            mt={{ base: 16 }}
            fullWidth
            type="submit"
            disabled={isPassword}
          >
            Atualizar senha
          </Button>
        </Flex>
      </Center>
      <Modal
        centered
        opened={opened} onClose={close} withCloseButton={false}>
        <Flex direction="column" align="center" gap={20}>
          {success ?
            <IconExclamationCircle
              color={theme.colors?.alerts[0]}
              size={88}
              stroke={0.5}
            />
            :
            <IconCircleCheck
              color={theme.colors?.alerts[2]}
              size={88}
              stroke={0.5}
            />
          }
          <Flex direction="column" align={"center"} gap={4}>
            <Text align='center' fz={{ base: 20 }} fw={600} lh={{ base: "150%" }} color="dark.1">
              {success ? "Não foi possível atualizar sua senha!" : "Senha atualizada com sucesso!"}
            </Text>
            <Text align={"center"} fz={{ base: 16 }} lh={{ base: "150%" }} color="gray.2">
              {success ? "Volte para o aplicativo e envio um novo email!" : "Volte para o aplicativo!"}
            </Text>
          </Flex>
          <Button variant="primary" fullWidth onClick={close}>
            Fechar
          </Button>
        </Flex>
      </Modal>
    </BackgroundImage>
  );
}

export default ResetPassword