import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import React, { useState } from "react";
import { ModalOptions, Tag } from "../../../../../components";
import {
  IconArrowRight,
  IconAt,
  IconCheck,
  IconCircleX,
} from "@tabler/icons-react";
import { NavLink } from "react-router-dom";
import PopoverOffer from "../PopoverOffer";
import { deleteOffer, putOffer } from "../../../../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../../constants/queryClients";
import ModalValueCull from "./ModalValueCull";

interface Iprops {
  data?: any;
  breadcrump?: any;
}

const ListOffer: React.FC<Iprops> = ({ data, breadcrump }) => {
  const theme = useMantineTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>("");
  const [modalValue, setModalValue] = useState<boolean>(false);
  const [item, setItem] = useState<any>();

  const destroy = () => {
    setOpenModal(!openModal);
    setTypeModal("excluir");
  };

  const statusMutate = () => {
    changeStatus();
  };

  const { mutate: mutateDelete, isLoading: loadingDel } = useMutation(
    deleteOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["culls"]);
        setOpenModal(!openModal);
      },
      onError: () => {
        alert("Erro");
      },
    }
  );

  const { mutate: mutateSuspend, isLoading: loadingStatus } = useMutation(
    putOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["culls"]);
      },
      onError: () => {
        alert("Erro");
      },
    }
  );

  const changeStatus = () => {
    let obj = Object.assign({
      ...item,
      lot: null,
      is_accepted: typeModal === "aceitar" ? "true" : "false",
      //  is_accepted: null,
    });
    mutateSuspend(obj);
  };

  return (
    <>
      <ModalValueCull
        item={item}
        value_per_kg={breadcrump?.value}
        setModalValue={() => {
          setModalValue(false);
        }}
        modalValue={modalValue}
      />
      <ModalOptions
        loading={loadingStatus || loadingDel}
        openModal={openModal}
        textStepTwo={"oferta"}
        setOpenModal={() => setOpenModal(!openModal)}
        typeModal={typeModal}
        onClick={() => {
          if (typeModal === "excluir") {
            mutateDelete(item);
          } else {
            statusMutate();
          }
        }}
      />
      {data?.culls?.map((item: any, index: number) => {
        const notNull = item?.is_accepted === null ? true : false;
        return (
          <Flex
            key={index}
            w="100%"
            align={"center"}
            direction={"column"}
            sx={{
              borderTop: "1px solid transparent",
              ":hover": {
                animation: "ease-in",
                animationName: "ease-in",
                animationDuration: "30000ms",
                backgroundColor: theme.colors.gray[0],
                borderColor: theme.colors.gray[1],
              },
            }}
          >
            <Box
              w={"100%"}
              h={"136px"}
              m={"0px"}
              p={"0px"}
              style={{
                borderBottom: "1px solid" + theme.colors.gray[1],
                textDecoration: "none",
              }}
              sx={{
                ":hover": {
                  animation: "ease-in",
                  animationName: "ease-in",
                  animationDuration: "30000ms",
                  backgroundColor: theme.colors.gray[0],
                },
              }}
            >
              <Flex
                w={"100%"}
                h={"100%"}
                justify={"space-between"}
                align={"center"}
                mb={"29px"}
                p={"0px 8px"}
                gap={{ base: "40px", xl: "60px" }}
              >
                <Flex direction="column" align={"left"} w="100%">
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.farm?.name}
                  </Text>
                  <Flex align={"center"} m={"8px 0"}>
                    <IconAt color={theme.colors.gray[2]} size={"1.2rem"} />
                    <Text
                      fs={"14px"}
                      ff={"Poppins"}
                      color="#2E72E9"
                      fw={300}
                      ml={"13px"}
                    >
                      {item?.farm?.email}
                    </Text>
                  </Flex>
                  {/*  <Flex align={"center"}>
                    <IconPhoneCall
                      color={theme.colors.gray[2]}
                      size={"1.2rem"}
                    />
                    <Text
                      fs={"14px"}
                      ff={"Poppins"}
                      color={theme.colors.gray[2]}
                      fw={300}
                      ml={"13px"}
                    >
                      {item?.farm?.user?.[0]?.phone}
                    </Text>
                  </Flex> */}
                </Flex>
                <Flex direction={"column"} align={"left"} w="100%">
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.farm?.name}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    {item?.farm?.address}
                  </Text>
                </Flex>
                <Flex direction={"column"} align={"left"}>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.total_animals}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Animais
                  </Text>
                </Flex>
                <Flex direction={"column"} align={"left"}>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.category}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Categoria
                  </Text>
                </Flex>
                <Flex direction={"column"} align={"left"} miw="80px">
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.weight_total} Kg
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Peso vivo
                  </Text>
                </Flex>
                <Flex direction={"column"} align={"left"} miw="100px">
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {(breadcrump?.value).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Valor por Kg
                  </Text>
                </Flex>
                <Flex gap="40px">
                  {!!notNull && (
                    <Flex gap={"6px"} w={"164px"} justify="end">
                      <Tooltip
                        label="Aceitar oferta"
                        color={theme.colors.gray[2]}
                        withArrow
                        position="bottom-end"
                      >
                        <Button
                          variant="success"
                          w={"40px"}
                          p={0}
                          onClick={() => {
                            setTypeModal("aceitar");
                            setItem(item);
                            setOpenModal(!openModal);
                          }}
                        >
                          <IconCheck color="white" />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        label="Recusar oferta"
                        color={theme.colors.gray[2]}
                        withArrow
                        position="bottom-end"
                      >
                        <Button
                          variant="red"
                          w={"40px"}
                          p={0}
                          onClick={() => {
                            setTypeModal("recusar");
                            setItem(item);
                            setOpenModal(!openModal);
                          }}
                        >
                          <IconCircleX />
                        </Button>
                      </Tooltip>
                    </Flex>
                  )}
                  {!notNull && (
                    <Flex align="center" gap={34}>
                      <Tag
                        status={item?.is_accepted === "false" ? "red" : "green"}
                        w={"82px"}
                        h={"32px"}
                        ta={"center"}
                        title={
                          item?.is_accepted === "false" ? "Negado" : "Aceito"
                        }
                      />
                      <PopoverOffer
                        block={item?.is_accepted === "true"}
                        funcEditValue={() => {
                          setItem(item);
                          setTypeModal("");
                          setModalValue(true);
                        }}
                        funcBlock={() => {
                          setTypeModal("recusar");
                          setItem(item);
                          setOpenModal(!openModal);
                        }}
                        funcUnblock={() => {
                          setTypeModal("aceitar");
                          setItem(item);
                          setOpenModal(!openModal);
                        }}
                        funcDestroy={() => {
                          setItem(item);
                          destroy();
                        }}
                      />
                    </Flex>
                  )}
                  <Button
                    component={NavLink}
                    to="/demandas/ofertas/detalhes-oferta"
                    state={{
                      animals: item,
                      item: breadcrump,
                      farm: item?.farm,
                    }}
                    color={theme.colors.primary[0]}
                    bg={"transparent"}
                    sx={{
                      ":hover": {
                        backgroundColor: theme.colors.primary[0],
                      },
                    }}
                  >
                    <IconArrowRight color={theme.colors.dark[0]} />
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        );
      })}
    </>
  );
};

export default ListOffer;
