import {
  Box,
  Button,
  Flex,
  Modal,
  PasswordInput,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Controller, useForm } from "react-hook-form";
import { IFormLogin } from "../../pages/SignIn/type";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaLogin } from "../../pages/SignIn/SchemaLogin";
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconEyeOff,
  IconEye,
} from "@tabler/icons-react";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postSignUp } from "../../hooks/useSignUp";

interface IProps {
  idMap?: string | number;
  title?: string;
  subTitle?: string;
  labelInputLogin?: string;
  labelInputPassword?: string;
  iconButton?: any;
  typeButton?: string;
}

const ModalAccess: React.FC<IProps> = ({
  typeButton,
  iconButton,
  idMap,
  title,
  subTitle,
  labelInputLogin,
  labelInputPassword,
}) => {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [stepAccess, setStepAccess] = useState<number>(0);
  const editRegister = title === "Alterar acesso";

  const cancel = () => {
    setStepAccess(0);
  };

  const stepOne = () => {
    setStepAccess(1);
  };

  const stepTwo = () => {
    setStepAccess(2);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormLogin>({
    mode: "onChange",
    resolver: yupResolver(schemaLogin),
  });

  const { mutate: createSignUp } = useMutation(postSignUp, {
    onSuccess: () => {
      reset();
      stepTwo();
    },
  });

  const onSubmit = (data: IFormLogin) => {
    let obj = Object.assign({
      ...data,
      realm_id: idMap,
    });
    editRegister ? stepOne() : createSignUp(obj);
  };
  return (
    <>
      <Modal
        centered
        opened={opened}
        onClose={close}
        size="auto"
        styles={{
          content: {
            background: "#F8F9FA",
            borderRadius: "16px",
          },
          header: {
            padding: 0,
          },
          close: {
            display: stepAccess === 0 ? "block" : "none",
            right: "48px",
            top: "55px",
            position: "absolute",
          },
        }}
      >
        {stepAccess === 0 ? (
          <Flex direction="column" p={{ base: 48 }} gap={{ base: 48 }}>
            <Box>
              <Text
                fz={{ base: 24 }}
                fw={600}
                lh={{ base: "32px" }}
                color="primary.1"
                pr={71}
              >
                BodeTech
                {!!title ? (
                  <Text color="dark.1" component="span" fw={400}>
                    {" - "}
                    {title === "Criar acesso"
                      ? "Criar acesso para frigorico"
                      : title}
                  </Text>
                ) : null}
              </Text>
              <Text
                mt={16}
                fz={{ base: 16 }}
                lh={{ base: "32px" }}
                color="gray.2"
              >
                {subTitle}
              </Text>
            </Box>
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              style={{ width: "100%", height: "100%" }}
            >
              <Controller
                control={control}
                name="email"
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextInput
                    label={labelInputLogin}
                    withAsterisk
                    placeholder="Email"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={errors.email?.message}
                    rightSection={
                      !!errors.email?.message ? (
                        <IconAlertTriangle color="#DE2828" />
                      ) : (
                        ""
                      )
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    mt={{ base: 32 }}
                    label={labelInputPassword}
                    withAsterisk
                    placeholder="Senha"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={
                      errors.password?.message
                      //|| "oi"
                    }
                    visibilityToggleIcon={({ reveal }) =>
                      errors.password?.message ? (
                        <IconAlertTriangle color="#DE2828" />
                      ) : reveal ? (
                        <IconEyeOff color="#BFC0C2" />
                      ) : (
                        <IconEye color="#BFC0C2" />
                      )
                    }
                  />
                )}
              />
              <Button
                variant="primary"
                mt={{ base: 60 }}
                fullWidth
                type="submit"
                disabled={!isDirty || !isValid}
              >
                {title}
              </Button>
            </form>
          </Flex>
        ) : (
          <Flex
            direction="column"
            align="center"
            pt={32}
            p={16}
            gap={24}
            w={{ base: 438 }}
          >
            {stepAccess === 1 ? (
              <IconExclamationCircle
                color={theme.colors?.alerts[1]}
                size={88}
                stroke={0.5}
              />
            ) : (
              <IconCircleCheck
                color={theme.colors?.alerts[2]}
                size={88}
                stroke={0.5}
              />
            )}
            <Flex direction="column" align={"center"} gap={4}>
              <Text
                fz={{ base: 20 }}
                fw={600}
                lh={{ base: "150%" }}
                color="dark.1"
              >
                {stepAccess === 1 ? "Atenção" : "Concluído"}!
              </Text>
              <Text fz={{ base: 16 }} lh={{ base: "150%" }} color="gray.2">
                {stepAccess === 2
                  ? `Acesso${
                      editRegister ? " alterado " : " cadastrado "
                    } com sucesso!`
                  : "Deseja mesmo alterar informações de acesso ?"}
              </Text>
            </Flex>
            {stepAccess === 1 ? (
              <Flex gap={12} w="100%">
                <Button variant="primaryOutline" onClick={cancel} fullWidth>
                  Não, cancelar
                </Button>
                <Button variant="primary" onClick={stepTwo} fullWidth>
                  Sim, Alterar
                </Button>
              </Flex>
            ) : (
              <Button
                variant="primary"
                fullWidth
                onClick={() => {
                  setStepAccess(0);
                  close();
                }}
              >
                Fehar
              </Button>
            )}
          </Flex>
        )}
      </Modal>

      <Button
        leftIcon={!!iconButton ? iconButton : null}
        variant={!!typeButton ? typeButton : "primary"}
        onClick={() => {
          open();
        }}
        miw={146}
      >
        {title}
      </Button>
    </>
  );
};

export default ModalAccess;
