import {
  Button,
  Flex,
  Pagination,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { DATA_SELECT_ORDER } from "../../../constants/contants";
import { useState } from "react";
import { ListSkeleton, ScreenEmpty, SelectLocale } from "../../../components";
import ListUsers from "./ListUsers.tsx";
import { useUsers } from "../../../hooks";

const User: React.FC = () => {
  const [activePage, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string>("latest");
  const [valueUf, setValueUf] = useState<string | undefined>(undefined);
  const [valueCity, setValueCity] = useState<string | undefined>(undefined);
  const { data: user } = useUsers(
    activePage,
    4,
    order,
    search,
    valueCity,
    valueUf
  );

  const total = user?.totalUsers;

  return (
    <Flex justify="center" w="100%" mt={38} h="calc(100vh - 140px)">
      <Flex maw={1680} w="100%" mx={{ base: 20 }} direction="column">
        <Flex align="center" gap={32} mb={34}>
          <TextInput
            type="search"
            w="100%"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
            placeholder="Pesquisar"
            icon={<IconSearch color="#BFC0C2" size={18} />}
          />
          <Flex gap={40} w="auto">
            <Select
              w="100%"
              miw={{ base: 221 }}
              placeholder="Todos"
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={DATA_SELECT_ORDER}
              value={order}
              defaultValue="latest"
              onChange={(e) => setOrder(e ? e : "")}
            />
            <SelectLocale
              valueUf={valueUf}
              setValueUf={setValueUf}
              valueCity={valueCity}
              setValueCity={setValueCity}
            />
            <Button miw={191} fullWidth variant="primary">
              Baixar Dados
            </Button>
          </Flex>
        </Flex>
        {!user ? (
          <ListSkeleton repeat={4} />
        ) : !!user && total === 0 ?
          <ScreenEmpty text="Nenhum usuário encontrado" />
          : (
            <>
              <ListUsers data={user} />
              <Flex
                justify="space-between"
                align="center"
                pt={{ base: 41 }}
                mt="auto"
                pb={{ base: 55 }}
              >
                {total !== undefined ? (
                  <Text color="gray.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
                    {total} Usuário
                    {total > 1 ? "s " : null}
                    cadastrado
                    {total > 1 ? "s" : null}
                  </Text>
                ) : (
                  "Nenhum usuário"
                )}
                <Pagination
                  value={activePage}
                  onChange={setPage}
                  total={!user?.totalPages ? 1 : user?.totalPages}
                />
              </Flex>
            </>
          )}
      </Flex>
    </Flex>
  );
};

export default User;
