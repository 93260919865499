import {
  Anchor,
  Box,
  Button,
  Flex,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { IconAt, IconCircleX, IconPhoneCall } from "@tabler/icons-react";
import { ModalOptions, PopoverOptions, Tag } from "../../../../components";
import { IFarm, IFarmData } from "../../../../interfaces/IFarm";
import { deleteFarm, putFarm, useFarmByid } from "../../../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../constants/queryClients";

interface IProps {
  data?: IFarm;
}

const ListProperties: React.FC<IProps> = ({ data }) => {
  const theme = useMantineTheme();
  const [itemId, setItemId] = useState<any>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>("");
  const { data: farmId } = useFarmByid(itemId);
  const typeFunc = typeModal === "excluir";

  const excluir = () => {
    setOpenModal(!openModal);
    setTypeModal("excluir");
  };

  const { mutate: mutateStatus, isLoading: loadingStatus } = useMutation(
    putFarm,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["farms"]);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: loadingDel } = useMutation(
    deleteFarm,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["farms"]);
      },
    }
  );

  const statusChange = () => {
    let obj = Object.assign({
      ...farmId,
      qualified:
        typeModal === "bloquear"
          ? "blocked"
          : typeModal === "desbloquear"
            ? null
            : "blocked",
    });
    mutateStatus(obj);
  };

  return (
    <>
      <ModalOptions
        textStepTwo={"propriedade"}
        loading={loadingStatus || loadingDel}
        openModal={openModal}
        setOpenModal={() => setOpenModal(!openModal)}
        typeModal={typeModal}
        onClick={typeFunc ? () => mutateDelete(itemId) : statusChange}
      />
      {data?.farms?.map((item: IFarmData, index: number) => (
        <Flex
          key={index}
          w="100%"
          align="center"
          gap={50}
          py={{ base: 12 }}
          mt={8}
          sx={{ borderBottom: "1px solid", borderColor: theme.colors.gray[1] }}
        >
          <Box w="30%">
            <Text

              fz={{ base: 18 }}
              fw={500}
              lh={{ base: "24px" }}
              color="dark.1"
              truncate
            >

              {item?.name}
            </Text>
            <Anchor
              target="_blank"
              href={`mailto:${item?.email}`}
              color="#2E72E9"
              fw={300}
              fz={{ base: 14 }}
              lh={{ base: "24px" }}
              sx={{
                svg: {
                  marginBottom: "-3px",
                  marginRight: "8px",
                },
              }}
            >
              <Text maw={150} truncate>
                <IconAt size={16} color="#707072" />
                {item?.email}
              </Text>
            </Anchor>
            <Text>
              <Anchor
                target="_blank"
                href={`+55${item?.phone}`}
                fz={{ base: 14 }}
                fw={{ base: 300 }}
                lh={{ base: "24px" }}
                color="#707072"
                sx={{
                  svg: {
                    marginBottom: "-3px",
                    marginRight: "8px",
                  },
                }}
              >
                <IconPhoneCall size={16} color="#707072" />
                {item?.user?.phone}
              </Anchor>
            </Text>
          </Box>
          <Box w="40%" >
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {`${!!item?.address ? item?.address : "--- "}, ${!!item?.city ? item?.city : "--- "
                },${!!item?.state ? item?.state : " -- "
                }
              `}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Localização
            </Text>
          </Box>
          <Box w="20%">
            <Text
              truncate
              tt="capitalize"
              color="dark.1"
              fz={{ base: 18 }}
              lh={{ base: "24px" }}
            >
              {!!item?.user?.name ? item?.user?.name : "---"}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Responsável
            </Text>
          </Box>
          <Box>
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {!!item?.total_animals ? item?.total_animals : "0"}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Anima
              {!!item?.total_animals && item?.total_animals > 1 ? "is" : "l"}
            </Text>
          </Box>
          <Box>
            {item?.qualified === null ? (
              <Flex gap={"6px"}>
                <Button
                  variant="success"
                  w={"112px"}
                  onClick={() => {
                    mutateStatus({
                      ...item,
                      qualified: true,
                    });
                  }}
                >
                  Vincular
                </Button>
                <Button
                  variant="red"
                  w={"40px"}
                  p={0}
                  onClick={() => {
                    mutateStatus({
                      ...item,
                      qualified: false,
                    });
                  }}
                >
                  <IconCircleX />
                </Button>
              </Flex>
            ) : (
              <Tag
                h={52}
                sx={{
                  span: {
                    padding: "6px 12px",
                    whiteSpace: "normal",
                    textAlign: "center",
                  },
                }}
                w={158}
                status={
                  item.qualified === "true"
                    ? "green"
                    : item.qualified === "false"
                      ? "red"
                      : "yellow"
                }
                title={
                  item.qualified === "true"
                    ? "Propriedade vinculada"
                    : item.qualified === "blocked"
                      ? "Propriedade bloqueada"
                      : "Propriedade recusada"
                }
              />
            )}
          </Box>
          <PopoverOptions
            btns={[
              item.qualified !== "blocked" ? "Bloquear" : "Desbloquear",
              "Desvincular",
            ]}
            block={item.qualified}
            setStatus={() => {
              setOpenModal(!openModal);
              setItemId(item?._id);
            }}
            setTypeModal={setTypeModal}
            deleteItem={excluir}
            disabled={item?.qualified === null}
          />
        </Flex>
      ))}
    </>
  );
};

export default ListProperties;
