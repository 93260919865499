import { Anchor, Box, Flex, Text, useMantineTheme } from "@mantine/core";
import { IFridge, IFridgeData } from "../../../../interfaces/IFridge";
import { IconAt, IconPhoneCall } from "@tabler/icons-react";
import { convertPhone } from "../../../../utils/convert";
import { date } from "../../../../utils/date";
import { ModalAccess } from "../../../../components";

interface Iprops {
  data?: IFridge;
}

const ListFridges: React.FC<Iprops> = ({ data }) => {
  const theme = useMantineTheme();

  return (
    <>
      {data?.fridges?.map((item: IFridgeData) => (
        <Flex
          key={item?._id}
          align="center"
          gap={{ base: 20, 1420: 50 }}
          py={{ base: 12 }}
          mt={8}
          sx={{ borderBottom: "1px solid", borderColor: theme.colors.gray[1] }}
        >
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Text
              fz={{ base: 18 }}
              fw={500}
              lh={{ base: "24px" }}
              color="dark.1"
            >
              {item?.name}
            </Text>
            <Anchor
              target="_blank"
              href={`mailto:${item?.email}`}
              color="#2E72E9"
              fw={300}
              fz={{ base: 14 }}
              lh={{ base: "24px" }}
              sx={{
                svg: {
                  marginBottom: "-3px",
                  marginRight: "8px",
                },
              }}
            >
              <IconAt size={16} color="#707072" />
              {item?.email}
            </Anchor>
            <Text>
              <Anchor
                target="_blank"
                href={`+55${item?.phone}`}
                fz={{ base: 14 }}
                fw={{ base: 300 }}
                lh={{ base: "24px" }}
                color="#707072"
                sx={{
                  svg: {
                    marginBottom: "-3px",
                    marginRight: "8px",
                  },
                }}
              >
                <IconPhoneCall size={16} color="#707072" />
                {convertPhone(item?.phone)}
              </Anchor>
            </Text>
          </Box>
          <Box w="100%">
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {`${!!item?.city ? item?.city : "--- "}, 
              ${!!item?.state ? item?.state : " -- "
                }`}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Localização
            </Text>
          </Box>
          <Box miw={200}>
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {item?.responsible?.name}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Responsável
            </Text>
          </Box>
          <Box w="100%" maw={200}>
            <Text color="dark.1" fz={{ base: 18 }} lh={{ base: "24px" }}>
              {date(item?.created_at)}
            </Text>
            <Text mt={4} fz={{ base: 14 }} fw={{ base: 300 }} color="gray.2">
              Cadastro
            </Text>
          </Box>
          <ModalAccess
            title="Criar acesso"
            subTitle="Digite informações de Login e senha para o painel frigorífico."
            labelInputLogin="Digite novo email"
            labelInputPassword="Digite nova senha"
            idMap={item?.responsible?.realm_id}
          />
        </Flex>
      ))}
    </>
  );
};

export default ListFridges;
