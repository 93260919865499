import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Flex,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { BackgroundLogin } from "../../assets";
import { Controller, useForm } from "react-hook-form";
import { IFormLogin } from "./type";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaLogin } from "./SchemaLogin";
import { IconAlertTriangle, IconEyeOff } from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";

const SignIn: React.FC = () => {
  const { postAuthorize } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormLogin>({
    mode: "onChange",
    resolver: yupResolver(schemaLogin),
  });
  const onSubmit = (data: IFormLogin) => postAuthorize(data);

  const navigate = useNavigate();

  return (
    <BackgroundImage src={BackgroundLogin} opacity="0.8">
      <Center bg="white" mih="100vh">
        <Flex
          m={20}
          w="100%"
          maw={540}
          bg="white.1"
          direction="column"
          p={{ base: 48 }}
          gap={{ base: 48 }}
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Box>
            <Text
              fz={{ base: 24 }}
              fw={600}
              lh={{ base: "32px" }}
              color="primary.1"
            >
              BodeTech
            </Text>
            <Text
              mt={16}
              fz={{ base: 16 }}
              lh={{ base: "32px" }}
              color="gray.2"
            >
              Digite suas informações de Login e acesse o painel.
            </Text>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            style={{ width: "100%", height: "100%" }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field: { onBlur, onChange, value } }) => (
                <TextInput
                  label="Digite seu acesso"
                  withAsterisk
                  placeholder="Email"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors.email?.message}
                  rightSection={
                    !!errors.email?.message ? (
                      <IconAlertTriangle color="#DE2828" />
                    ) : (
                      ""
                    )
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => (
                <PasswordInput
                  mt={{ base: 32 }}
                  label="Digite sua senha"
                  withAsterisk
                  placeholder="Senha"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  error={errors.password?.message}
                  visibilityToggleIcon={({ reveal }) =>
                    errors.password?.message ? (
                      <IconAlertTriangle color="#DE2828" />
                    ) : reveal ? (
                      <IconEyeOff color="#BFC0C2" />
                    ) : (
                      <IconEye color="#BFC0C2" />
                    )
                  }
                />
              )}
            />
            <Button
              variant="primary"
              mt={{ base: 60 }}
              fullWidth
              type="submit"
              disabled={!isDirty || !isValid}
            >
              Acessar
            </Button>
          </form>
        </Flex>
      </Center>
    </BackgroundImage>
  );
};

export default SignIn;
