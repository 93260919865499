import { Anchor, Breadcrumbs as BreadcrumbsMantine, Text, useMantineTheme } from "@mantine/core";
import { NavLink } from "react-router-dom";

interface IProps {
  item?: any;
  state?: any
}

const BreadCrumbs: React.FC<IProps> = ({ item, state }) => {
  const theme = useMantineTheme();

  return (
    <BreadcrumbsMantine mb="32px">
      {item.map((item: any, index: number) => (
        <NavLink
          key={index}
          to={item.href}
          state={state}
          style={{
            textDecoration: "none",
            color: !!item.href ? "#BFC0C" : "#303030",
            cursor: !!item.href ? "pointer" : "inherit",
            fontSize: "16px",
          }}
        >
          <Text
            sx={{
              ":hover": {
                animation: "ease-in",
                animationName: "ease-in",
                animationDuration: "30000ms",
                color: !!item.href ? theme.colors.dark[2] : "",
              },
            }}
          >
            {item.title}
          </Text>
        </NavLink>
      ))}
    </BreadcrumbsMantine>
  );
};

export default BreadCrumbs; 