import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Flex,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { BackgroundLogin } from "../../assets";
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCircleCheck,
} from "@tabler/icons-react";

const PurchaseSuccess: React.FC = () => {
  const location = "/success-purchase";

  return (
    <BackgroundImage src={BackgroundLogin} opacity="0.8">
      <Center bg="white" mih="100vh">
        <Flex
          m={20}
          w="100%"
          maw={540}
          bg="white.1"
          direction="column"
          p={{ base: 48 }}
          gap={{ base: 24 }}
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
          }}
          align={"center"}
        >
          <IconCircleCheck color="#E5A402" size={"80px"} />
          <Text
            align="center"
            mt={16}
            fz={{ base: 16 }}
            lh={{ base: "32px" }}
            color="gray.2"
          >
            Sua compra foi efetuada com sucesso! <br />
            Retorne ao aplicativo e aproveite seu novo plano!
          </Text>
        </Flex>
      </Center>
    </BackgroundImage>
  );
};

export default PurchaseSuccess;
