import { Flex, Pagination, Select, Text, TextInput } from "@mantine/core";
import React, { useState } from "react";
import ListOffer from "./ListOffer";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { DATA_SELECT_ORDER } from "../../../../constants/contants";
import {
  BreadCrumbs,
  DivFather,
  ListSkeleton,
  ScreenEmpty,
} from "../../../../components";
import { useLocation } from "react-router-dom";
import { useOffers } from "../../../../hooks";
import { useAuth } from "../../../../context/auth";

const Offer: React.FC = () => {
  const {
    state: { item },
  } = useLocation();
  //const auth = useAuth()
  const [order, setOrder] = useState<string>("sale_date_desc");
  const [search, setSearch] = useState<string>("");
  const [activePage, setPage] = useState(1);
  const { data: offers } = useOffers(
    "offer",
    undefined,
    order, //order
    undefined, //take
    activePage, //page
    undefined, // item?.quantity_of_animals,
    item?.category, //category animal
    undefined, // item?.weigth, //weigth
    undefined, //item?.category,   //category
    undefined, //item?.value, //value_to_kg
    undefined //!!valueCity ? valueCity : undefined,  //city
  );

  console.log("TCL: Offer:React.FC -> offers", offers);

  const items = [
    { title: "Ordens de compra", href: "/" },
    { title: "Ofertas" },
  ];

  const totaloffers = !!offers?.totalCulls ? offers?.totalCulls : 0;
  const totalPages = !!offers?.totalPages ? offers?.totalPages : 0;

  return (
    <DivFather>
      <BreadCrumbs item={items} />
      <Flex align={"center"} gap={32} justify={"space-between"} pb="30px">
        <TextInput
          w="100%"
          type="search"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Pesquisar pelo título"
          icon={<IconSearch color="#BFC0C2" size={18} />}
        />
        <Select
          w="100%"
          maw={240}
          miw={{ base: 221 }}
          placeholder="Todos"
          rightSection={<IconChevronDown size="1rem" />}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: "none" } }}
          data={[
            {
              value: "sale_date_desc",
              label: "Data desc",
            },
            {
              value: "sale_date_asc",
              label: "Data cresc",
            },
          ]}
          value={order}
          defaultValue="latest"
          onChange={(e) => setOrder(e ? e : "")}
        />
        {/* <Select
          maw={240}
          searchable
          clearable
          value={valueCity}
          w="100%"
          miw={{ base: 261 }}
          onChange={setValueCity}
          placeholder="Cidade"
          rightSection={!valueCity ? <IconChevronDown size="1rem" /> : null}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: "none" } }}
          data={cities}
        /> */}
      </Flex>
      {!offers ? (
        <ListSkeleton repeat={5} />
      ) : !!offers && totaloffers === 0 ? (
        <ScreenEmpty text="Nenhuma oferta encontrada" />
      ) : (
        <>
          <ListOffer data={offers} breadcrump={item} />
          <Flex
            align={"center"}
            justify={"space-between"}
            pt={"71px"}
            mb={"55px"}
            mt="auto"
          >
            <Text bottom={"0px"} color="#BFC0C2">
              {totaloffers} ofertas cadastradas
            </Text>
            <Pagination
              mt="auto"
              value={activePage}
              onChange={setPage}
              total={totalPages}
            />
          </Flex>
        </>
      )}
    </DivFather>
  );
};
export default Offer;
