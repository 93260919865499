import { BadgeProps, Flex, Text, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";

interface IProps extends BadgeProps {
  status: "gray" | "green" | "red" | "yellow";
  title?: string;
  select?: boolean;
  border?: boolean;
}

const Tag: React.FC<IProps> = ({ status, title, border, ...props }) => {
  const [content, setContent] = useState<string | any>({});
  const theme = useMantineTheme();
  useEffect(() => {
    switch (status) {
      case "gray":
        return setContent({
          backgroundColor: theme.colors.gray[0],
          color: theme.colors.gray[2],
          border: theme.colors.gray[2],
        });
      case "yellow":
        return setContent({
          backgroundColor: theme.colors.alertsOpacity[5],
          color: theme.colors.alerts[1],
          border: theme.colors.alerts[1],
        });
      case "red":
        return setContent({
          backgroundColor: theme.colors.alertsOpacity[0],
          color: theme.colors.alerts[0],
          border: theme.colors.alerts[0],
        });

      case "green":
        return setContent({
          backgroundColor: theme.colors.alertsOpacity[4],
          color: theme.colors.alerts[2],
          border: theme.colors.alerts[2],
        });

      default:
        return setContent({
          text: "Aprovado",
          backgroundColor: "#E9F8EB",
          color: "#23BD32",
          border: "#169C3C",
        });
    }
  }, [status]);
  return (
    <Flex
      justify={"center"}
      align={"center"}
      color={content.color}
      bg={content.backgroundColor}
      h="32px"
      fs="14px"
      fw={500}
      lh="20px"
      p={8}
      style={{
        border: "1px solid",
        borderColor: border ? content.border : "transparent",
        borderRadius: "sm",
        alignItems: "center",
      }}
      {...props}
    >
      <Text color={status === "gray" ? "#707072" : status} ta={"center"}>
        {title}
      </Text>
    </Flex>
  );
};

export default Tag;
