import {
  Button,
  Flex,
  Modal,
  NumberInput,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { putOffer } from "../../../../../../hooks";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../../../constants/queryClients";

interface IProps {
  modalValue: boolean;
  setModalValue: () => void;
  value_per_kg?: any;
  item?: any;
}

const ModalValueCull: React.FC<IProps> = ({
  modalValue,
  setModalValue,
  value_per_kg,
  item,
}) => {
  const theme = useMantineTheme();
  const [value, setValue] = useState<number | undefined>(undefined);

  const { mutate: mutateSuspend, isLoading: loadingStatus } = useMutation(
    putOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["culls"]);
        setModalValue();
      },
      onError: () => {
        alert("Erro");
      },
    }
  );

  const changeStatus = () => {
    if (value !== undefined) {
      let obj = Object.assign({
        ...item,
        lot: undefined,
        value: value?.toFixed(2),
        value_per_kg: value_per_kg?.toFixed(2),
        status: "historic",
        historic_sale_date: new Date(),
      });
      mutateSuspend(obj);
      console.log("TCL: changeStatus -> obj", obj);
    }
  };

  return (
    <Modal
      opened={modalValue}
      onClose={setModalValue}
      centered
      withCloseButton={false}
      styles={{
        body: {
          padding: 32,
        },
        content: {
          borderRadius: 8,
          border: "1px solid",
          borderColor: theme.colors.gray[1],
        },
      }}
      size="auto"
    >
      <Flex gap={30} w="100%" direction="column" miw={{ sm: "350px" }}>
        <NumberInput
          value={value}
          onChange={(x: number | "") => setValue(x === "" ? 0 : +x)}
          placeholder="Informe o peso total"
          label="Informe o peso total do abate!"
          precision={2}
          decimalSeparator=","
          min={0}
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
        <Flex gap={12} w="100%">
          <Button
            variant={true ? "primaryOutline" : "primary"}
            onClick={() => {
              setModalValue();
              setValue(undefined);
            }}
            fullWidth
          >
            Cancelar
          </Button>
          <Button
            variant={true ? "primary" : "danger"}
            onClick={changeStatus}
            fullWidth
            disabled={value === undefined || value <= 0}
            loading={loadingStatus}
          >
            Confirmar
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalValueCull;
