import {
  Button,
  Flex,
  LoadingOverlay,
  Pagination,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { DATA_SELECT_ORDER } from "../../../constants/contants";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { DivFather, ListSkeleton, ScreenEmpty } from "../../../components";
import ListHistory from "./ListHistory";
import { useDisclosure } from "@mantine/hooks";
import { useOffers } from "../../../hooks";

const History: React.FC = () => {
  const [active, { toggle }] = useDisclosure(false);
  const [order, setOrder] = useState<string>("historic_sale_date_desc");
  const [fCategory, setFCategory] = useState<string | null>(null);
  const [search, setSearch] = useState<string>("");
  const [activePage, setPage] = useState(1);

  const { data: offers } = useOffers(
    "historic", // status
    search,
    order, //order
    //undefined, //take
    4,
    activePage, //page
    undefined, //quantidade animais
    !!!fCategory ? undefined : fCategory, //category
    undefined, //weigth
    undefined, //value_to_kg
    "true"
  );
  console.log("TCL: History:React.FC -> offers", offers);

  useEffect(() => {
    setPage(1);
  }, [fCategory]);

  /* const customLoader = (
    <svg
      width="120px"
      height="120px"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={"#FFB600"}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  ); */

  const totalHistoric = !!offers?.totalCulls ? offers?.totalCulls : 0;
  const totalPages = !!offers?.totalPages ? offers?.totalPages : 0;

  return (
    <DivFather title="Histórico">
      {/*   <LoadingOverlay
        visible={active}
        loader={customLoader}
        onClick={toggle}
        overlayColor="black"
        overlayOpacity={0.5}
      /> */}
      <Flex align="center" gap={32}>
        <TextInput
          type="search"
          w="100%"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Pesquisar pelo título"
          icon={<IconSearch color="#BFC0C2" size={18} />}
        />

        <Select
          w="100%"
          maw={240}
          clearable
          miw={{ base: 221 }}
          placeholder="Categoria"
          rightSection={!!!fCategory ? <IconChevronDown size="1rem" /> : ""}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: "none" } }}
          data={[
            { label: "Caprino", value: "Caprino" },
            { label: "Ovino", value: "Ovino" },
          ]}
          value={fCategory}
          defaultValue="Todos"
          onChange={(e) => setFCategory(e ? e : "")}
        />
        <Select
          w="100%"
          maw={240}
          miw={{ base: 221 }}
          placeholder="Todos"
          rightSection={<IconChevronDown size="1rem" />}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: "none" } }}
          data={[
            {
              value: "historic_sale_date_desc",
              label: "Data desc",
            },
            {
              value: "historic_sale_date_asc",
              label: "Data cresc",
            },
          ]}
          value={order}
          defaultValue="latest"
          onChange={(e) => setOrder(e ? e : "")}
        />
        {/*   <Button miw={180} variant="primary" onClick={toggle}>
          Gerar documento
        </Button> */}
      </Flex>
      {!offers ? (
        <ListSkeleton repeat={5} />
      ) : !!offers && totalHistoric === 0 ? (
        <ScreenEmpty text="Nenhuma oferta encontrada" />
      ) : (
        <>
          <ListHistory data={offers} />
          <Flex
            align={"center"}
            justify={"space-between"}
            pt={"71px"}
            mt="auto"
            mb={"55px"}
          >
            <Text bottom={"0px"} color="#BFC0C2">
              {offers?.totalCulls} cadastradas
            </Text>
            <Pagination
              value={activePage}
              onChange={setPage}
              total={totalPages}
            />
          </Flex>
        </>
      )}
    </DivFather>
  );
};
export default History;
