import { MantineThemeOverride, Switch } from "@mantine/core";
import Root from "../router/Root";

export const theme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    primary: [
      "#E5A402", //0
      "#FFB600", //1
    ],
    primaryOpacity: [
      "#F2C082", //0
      "#F3D1A8", //1
      "#F3E3CE", //2
      "#FDF3E7", //3
    ],
    white: [
      "#FFFFFF", //0
      "#F8F9FA", //1
      "#F1F1F2", //2
      "#E9EAED", //3
      "#F8F9FA", //4
    ],
    gray: [
      "#E9EAED", //0
      "#BFC0C2", //1
      "#707072", //2
    ],
    dark: [
      "#000000", //0
      "#303030", //1
      "#535354", //2
    ],
    alerts: [
      "#DE2828", //0
      "#EDC100", //1
      "#18BA35", //2
      "#ff0202", //3
    ],
    alertsOpacity: [
      "rgba(222, 40, 40, 0.15)", //0
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #18BA35", //1
      "rgb(222 40 40 / 7%)", //2
      "#ffe5e5", //3
      "rgba(24, 186, 53, 0.15)", //4
      "rgba(255, 215, 36, 0.15)", //5
    ],
  },
  fontFamily: "Poppins",
  components: {
    Pagination: {
      styles: (theme) => ({
        control: {
          height: "48px",
          width: "48px",
          borderRadius: "4px",
          background: theme.colors.white[2],
          border: "1px solid",
          borderColor: theme.colors.gray[1],
          /*  ":not([data-disabled])": {
            ":hover": {
              color: theme.colors.white[0],
              background: theme.colors.primaryOpacity[1],
            },
          }, */
          "&[data-active]": {
            background: theme.colors.primary[1],
            ":not([data-disabled]):hover": {
              background: theme.colors.primaryOpacity[1],
            },
            ":disabled": {
              background: "#BFC0C2",
              borderColor: "#BFC0C2",
              color: "#fff",
              opacity: "1",
              "::placeholder": {
                color: "#6B6B6B",
              },
            },
          },
          ":disabled": {
            background: "#F1F1F2",
            borderColor: "#BFC0C2",
            color: "#303030",
            opacity: "1",
            "::placeholder": {
              color: "#6B6B6B",
            },
          },
        },
      }),
    },
    Tabs: {
      styles: (theme) => ({
        root: {
          display: "flex",
          alignItems: "center",
          button: {
            padding: "4px 12px",
          },
          "[data-active]": {
            span: {
              color: theme.colors.primary[1],
              fontWeight: 500,
            },
          },
        },
        tabLabel: {
          fontSize: "18px",
          lineHeight: "30px",
          fontWeight: 400,
        },
        tabsList: {
          gap: "24px",
        },
        tab: {
          ":hover": {
            background: theme.colors.white[2],
          },
        },
      }),
    },
    NumberInput: {
      styles: (theme) => ({
        label: {
          marginBottom: "4px",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "32px",
          color: theme.colors.dark[1],
        },
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
          fontSize: "12px",
        },
      }),
    },
    Switch: {
      styles: (theme) => ({
        root: {
          width: "100%",
        },
        body: {
          alignItems: "center",
          justifyContent: "space-between",
        },
        label: {
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "32px",
          color: theme.colors.dark[1],
        },
        /*  label: {
           marginBottom: "4px",
           fontSize: "18px",
           fontWeight: 500,
           lineHeight: "32px",
           color: theme.colors.dark[1],
         }, */
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
          fontSize: "12px",
        },
      }),
    },
    Input: {
      styles: (theme) => ({
        rightSection: {
          right: "16px",
        },
        icon: {
          left: "8px",
        },
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
          fontSize: "12px",
        },
        wrapper: {
          boxSizing: "border-box",
          borderRadius: "4px",
          WebkitBorderRadius: "4px",
          caretColor: theme.colors.primary[1],
          caretShape: "bar",
          Input: {
            fontWeight: 400,
            color: theme.colors.dark[0],
            padding: "12px 16px",
            backgroundColor: theme.colors.white[0],
            height: "54px ",
            fontSize: "18px",
            lineHeight: "30px",
            border: "1px solid ",
            borderColor: theme.colors.gray[1],
            borderRadius: "4px",
            WebkitBorderRadius: "4px",
            "::placeholder": {
              fontWeight: 400,
              color: theme.colors.gray[1],
            },
            ":hover": {
              background: theme.colors.white[2],
            },
            ":focus-within": {
              color: theme.colors.dark[1],
              border: "1px solid ",
              borderColor: theme.colors.primary[1],
            },
            ":disabled": {
              background: "#B9B9B9",
              opacity: "1",
              "::placeholder": {
                color: "#6B6B6B",
              },
            },
            ':not([value^=""])': {
              border: "1px solid ",
              borderColor: theme.colors.primary[1],
            },
          },
          "[data-invalid]": {
            color: theme.colors.alerts[0],
            background: theme.colors.alertsOpacity[3],
          },
        },
      }),
    },
    TextInput: {
      styles: (theme) => ({
        label: {
          marginBottom: "4px",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "32px",
          color: theme.colors.dark[1],
        },
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
          fontSize: "12px",
        },
        required: {
          color: theme.colors.alerts[0],
        },
        root: {
          color: theme.colors.dark[0],
        },
      }),
    },

    PasswordInput: {
      styles: (theme) => ({
        required: {
          color: theme.colors.alerts[0],
        },
        label: {
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "32px",
          color: theme.colors.dark[1],
        },
        innerInput: {
          height: "54px ",
          width: "100%",
          border: "1px solid",
          borderColor: theme.colors.gray[1],
          ":focus-within": {
            borderColor: theme.colors.primary[1],
          },
          "[data-invalid]": {
            background: "red",
          },
        },
        visibilityToggle: {
          ":hover": {
            background: "unset",
          },
        },
        input: {
          outline: "none",
          border: "none",
          height: "54px",
          "[data-invalid]": {
            border: "1px solid",
            borderColor: theme.colors.alerts[0],
            color: theme.colors.alerts[0],
          },
        },
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        item: {
          fontSize: "18px",
          color: theme.colors.dark[1],
          lineHeight: "24px",
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.primaryOpacity[3],
              color: theme.colors.primary[1],
            },
          },
          "&[data-hovered]": {
            backgroundColor: theme.colors.white[3],
          },
        },
        input: {
          borderColor: "transparent !important",
          outlineColor: "transparent !important",
        },
        dropdown: {
          borderColor: theme.colors.gray[1],
          borderWidth: "0px 1px 1px 1px",
        },
      }),
    },
    Skeleton: {
      styles: () => ({
        visible: {
          "::after": {
            background: "#d1d1d18c",
          },
        },
      }),
    },
    Button: {
      styles: (theme) => ({
        root: {
          ":focus-visible": {
            outlineColor: theme.colors.primary[1],
          },
          transition: "0.3s linear",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          padding: "12px 16px",
          "&[data-disabled]": {
            cursor: "not-allowed",
            background: theme.colors.gray[1],
            color: theme.colors.gray[2],
          },
        },
        leftIcon: {
          marginRight: "16px",
        },
        rightIcon: {
          marginLeft: "16px",
        },
      }),
      variants: {
        primary: (theme) => ({
          root: {
            height: "40px",
            borderRadius: "2px",
            background: theme.colors.primary[1],
            color: theme.colors.white[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              background: theme.colors.primary[0],
            }),
          },
        }),
        primaryOutline: (theme) => ({
          root: {
            height: "40px",
            borderRadius: "2px",
            border: "1.4px solid",
            borderColor: theme.colors.primary[1],
            color: theme.colors.primary[1],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              background: theme.colors.primaryOpacity[3],
            }),
          },
        }),
        danger: (theme) => ({
          root: {
            height: "40px",
            borderRadius: "2px",
            border: "1.4px solid",
            borderColor: theme.colors.alerts[0],
            color: theme.colors.alerts[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              background: theme.colors.alertsOpacity[0],
            }),
          },
        }),
        red: (theme) => ({
          root: {
            height: "40px",
            borderRadius: "2px",
            background: theme.colors.alerts[0],
            color: theme.colors.white[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              background: theme.colors.alerts[3],
            }),
          },
        }),

        success: (theme) => ({
          root: {
            height: "40px",
            borderRadius: "2px",
            background: theme.colors.alerts[2],
            color: theme.colors.white[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              background: theme.colors.alertsOpacity[1],
            }),
          },
        }),
        header: (theme) => ({
          root: {
            height: "54px",
            borderRadius: "4px",
            background: "unset",
            color: theme.colors.primary[1],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              background: theme.colors.white[2],
            }),
          },
        }),
        options: (theme) => ({
          root: {
            height: "54px",
            borderRadius: "4px",
            background: "unset",
            color: theme.colors.dark[0],
            zIndex: 1,
            overflow: "hidden",
            opacity: "1",
            ...theme.fn.hover({
              border: "unset",
              background: theme.colors.white[2],
            }),
          },
          inner: {
            justifyContent: "flex-start",
          },
        }),
      },
    },
    DatePickerInput: {
      styles: (theme) => ({
        placeholder: {
          color: theme.colors.gray[1],
        },
        label: {
          marginBottom: "4px",
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "32px",
          color: theme.colors.dark[1],
        },
        input: {
          border: "1px solid" + theme.colors.primary[0],
          height: "54px",
          color: theme.colors.dark[0],
          paddingLeft: "12px !important",
        },
        icon: {
          right: "8px",
          left: "auto",
        },
        error: {
          position: "absolute",
          color: theme.colors.alerts[0],
          lineHeight: "24px",
          fontSize: "12px",
          valueFormat: "DD/MM/YY",
        },
      }),
    },
    Breadcrumbs: {
      styles: (theme) => ({
        root: {
          textDecoration: "none",
          color: "#303030",
          fontSize: "16px",
        },
        breadcrumb: {
          color: theme.colors.gray[1],
        },
        separator: {
          color: theme.colors.gray[1],
        },
      }),
    },
  },
};
