import { Box, Button, Flex, Text, useMantineTheme } from "@mantine/core";
import { IconAt } from "@tabler/icons-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { IHistory } from "../../../../interfaces/IHistory";
import { date } from "../../../../utils/date";
import { Offer, OffersResponse } from "../../../../interfaces/IOffers";

interface IProps {
  data?: OffersResponse;
}

const ListHistory: React.FC<IProps> = ({ data }) => {
  const theme = useMantineTheme();

  console.log("TCL: ", data?.culls?.[0]);
  return (
    <Box mt={26}>
      {data?.culls?.map((item: Offer, index: number) => {
        return (
          <Button
            key={index}
            w="100%"
            h={"126px"}
            p={0}
            display="flex"
            styles={{
              root: {
                borderRadius: "2px",
                borderBottom: "1px solid #BFC0C2",
              },
              inner: { minWidth: "100% " },
              label: { minWidth: "100% " },
            }}
            component={NavLink}
            to="/historico/detalhes"
            state={{
              animals: item,
            }}
            color={theme.colors.primary[0]}
            bg={"transparent"}
            sx={{
              ":hover": {
                animation: "ease-in",
                animationName: "ease-in",
                animationDuration: "30000ms",
                backgroundColor: theme.colors.gray[0],
              },
            }}
          >
            <Flex
              miw={"100%"}
              h={"100%"}
              justify={"space-between"}
              align={"center"}
              p={"18px 8px"}
              gap={{ base: "40px", xl: "120px" }}
            >
              <Flex direction="column" align={"left"} w="100%">
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {item?.farm?.name}
                </Text>
                <Flex align={"center"} m={"8px 0"}>
                  <IconAt color={theme.colors.gray[2]} size={"1.2rem"} />
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color="#2E72E9"
                    fw={300}
                    ml={"13px"}
                  >
                    {item?.farm?.email}
                  </Text>
                </Flex>
                {/*   <Flex align={"center"}>
                  <IconPhoneCall color={theme.colors.gray[2]} size={"1.2rem"} />
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                    ml={"13px"}
                  >
                    {item?.farm?.user?.[0]?.phone}
                  </Text>
                </Flex> */}
              </Flex>
              <Flex direction={"column"} align={"left"} w="100%">
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {item?.farm?.name}
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  {item?.farm?.address}
                </Text>
              </Flex>
              <Flex direction={"column"} align={"left"}>
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {item?.total_animals}
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  Animais
                </Text>
              </Flex>
              {/*   <Flex direction={"column"} align={"left"} w="100%" maw="240px">
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {item?.category}
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  Categoria
                </Text>
              </Flex> */}
              <Flex direction={"column"} align={"left"}>
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {item?.value} Kg
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  Peso
                </Text>
              </Flex>
              <Flex direction={"column"} align={"left"}>
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  R$ {item?.value_per_kg?.toFixed(2)}
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  Valor por kg
                </Text>
              </Flex>
              <Flex direction={"column"} align={"left"}>
                <Text
                  fs={"18px"}
                  ff={"Poppins"}
                  color="#303030"
                  fw={500}
                  lh={"24px"}
                >
                  {date(item?.historic_sale_date)}
                </Text>
                <Text
                  fs={"14px"}
                  ff={"Poppins"}
                  color={theme.colors.gray[2]}
                  fw={300}
                >
                  Data
                </Text>
              </Flex>
            </Flex>
          </Button>
        );
      })}
    </Box>
  );
};
export default ListHistory;
