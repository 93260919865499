import { Flex, Text } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"

interface IProps {
    text: string
}

const ScreenEmpty: React.FC<IProps> = ({ text }) => {
    return (
        <Flex mt={"20vh"} direction={"column"} align="center" gap={20} >
            <IconAlertTriangle size="120" color="#FFB600" />
            <Text fz={32} align="center" color="dark.1" >{text}!</Text>
        </Flex>
    )
}

export default ScreenEmpty