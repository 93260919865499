import states_cities from "../resources/states-cities.json";
type TOptions = { label: string; value: any };

export const getStates = (): TOptions[] => {
  return states_cities.estados?.map((item) => ({
    label: item.nome,
    value: item.sigla,
  }));
};

export const getCities = (state: string | null | undefined): TOptions[] => {
  const cities = states_cities?.estados?.find((item) => item.sigla === state);
  return cities?.cidades?.map((value) => ({ label: value, value })) || [];
};

export const getCitiesState = (state: string | null | undefined): TOptions[] => {
  const cities = states_cities?.estados?.find((item) => item.nome === state);
  return cities?.cidades?.map((value) => ({ label: value, value })) || [];
};

export const getAllCities = (): TOptions[] => {
  let newCities: TOptions[] = [];

  states_cities.estados?.map((item) => {
    newCities.push(...getCities(item.sigla));
  });

  return newCities.sort((a, b) =>
    a.label < b.label ? -1 : a.label > b.label ? 1 : 0
  );
};
