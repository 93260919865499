import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { api } from "../api/api";

const getOffers = async <T>(
  status?: string,
  search?: string | undefined,
  order?: "za" | "az" | "latest" | "undefined" | string,
  take?: number,
  page?: number,
  total_animals?: number,
  category?: string,
  weight?: number,
  value_to_kg?: number | undefined,
  is_deleted?: string,
  city?: string
): Promise<any> => {
  let params = new URLSearchParams();
  if (status !== undefined) params.append("status", status?.toString());
  if (search !== undefined && search !== "")
    params.append("search", search?.toString());
  if (city !== undefined) params.append("city", city?.toString());
  if (order !== undefined) params.append("order", order?.toString());
  if (take !== undefined) params.append("take", take?.toString());
  if (page !== undefined) params.append("page", page?.toString());
  if (total_animals !== undefined)
    params.append("total_animals", total_animals?.toString());
  if (category !== undefined) params.append("category", category?.toString());
  if (weight !== undefined) params.append("weight", weight?.toString());
  if (value_to_kg !== undefined)
    params.append("value_to_kg", value_to_kg?.toString());
  if (is_deleted !== undefined) params.append("is_deleted", is_deleted);

  const { data } = await api.get<any>("/culls", { params: params });

  return data;
};

export const useOffers = <T>(
  status?: string,
  search?: string | undefined,
  order?: "za" | "az" | "latest" | "undefined" | string,
  take?: number,
  page?: number,
  total_animals?: number,
  category?: string,
  weight?: number,
  value_to_kg?: number,
  is_deleted?: string,
  city?: string
): UseQueryResult<any> => {
  return useQuery(
    [
      "culls",
      status,
      search,
      order,
      take,
      page,
      total_animals,
      category,
      weight,
      value_to_kg,
      is_deleted,
      city,
    ],
    async () => {
      try {
        return await getOffers(
          status,
          search,
          order,
          take,
          page,
          total_animals,
          category,
          weight,
          value_to_kg,
          is_deleted,
          city
        );
      } catch (error) {
        console.error("Erro na consulta de ofertas:", error);
        throw error; // Rejeita o erro para que o react-query possa lidar com ele
      }
    }
  );
};

export async function getOfferById(id: string) {
  const { data } = await api.get<any>(`culls/${id}`);
  return data;
}

export const useOfferByid = <T>(id: any) => {
  return useQuery(["culls", id], () => getOfferById(id));
};

export const putOffer = async (data: any) => {
  const resp = await api.put(`/culls/${data?._id}`, data);
  //console.log("TCL: putOffer -> resp", resp);
  return resp.data;
};

export const deleteOffer = async (id: any) => {
  //console.log("TCL: deleteOffer -> id", id?._id);
  const resp = await api.delete(`/culls/${id?._id}`);
  return resp.data;
};
