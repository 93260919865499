import { Button, Flex, Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

const ScreenComponents: React.FC = () => {
  return (
    <Flex align={"baseline"} direction="column" gap={10} m={20}>
      primary
      <Button variant="primary">primary</Button>
      primaryOutline
      <Button variant="primaryOutline">primaryOutline</Button>
      danger
      <Button variant="danger">danger</Button>
      success
      <Button variant="success">success</Button>
      primary
      <Button variant="primary" disabled>
        primary
      </Button>
      header
      <Button variant="header">header</Button>
      <Input
        icon={<IconSearch color="#BFC0C2" size={18} />}
        placeholder="Your email"
        radius="xl"
      />
    </Flex>
  );
};

export default ScreenComponents;
