// import { IDemands } from "../pages/interfaces/IDemands";
import { IDemands } from "../interfaces/IDemands";
import { IOffers } from "../interfaces/IOffers";
import { IProperty } from "../interfaces/IProperty";
// import { IProperty } from "../pages/interfaces/IProperty";

export type NAV_LINKS = {
  id: number;
  text: string;
  link: string;
};

export const DATA_NAVBAR_ADMIN: NAV_LINKS[] = [
  {
    id: 1,
    text: "Frigorífico",
    link: "/",
  },
  {
    id: 2,
    text: "Usuários",
    link: "usuarios",
  },
  {
    id: 3,
    text: "Propriedades",
    link: "propriedades",
  },
];

export const DATA_NAVBAR_FRIGORIFICO: NAV_LINKS[] = [
  {
    id: 1,
    text: "Demandas",
    link: "/",
  },
  {
    id: 2,
    text: "Propriedades",
    link: "propriedades",
  },
  {
    id: 3,
    text: "Histórico",
    link: "historico",
  },
  /*  {
     id: 4,
     text: "Receita dos Abates",
     link: "receita",
   }, */
];

export const DATA_SELECT_ORDER = [
  {
    value: "az",
    label: "A a Z",
  },
  {
    value: "za",
    label: "Z a A",
  },
  {
    value: "latest",
    label: "Mais recentes",
  },
];

export const DATA_SELECT_ORDER_OFFER = [
  {
    value: "az",
    label: "Cadastro",
  },
  {
    value: "az",
    label: "A a Z",
  },
  {
    value: "az",
    label: "Gênero",
  },
  {
    value: "az",
    label: "Raça",
  },
  {
    value: "latest",
    label: "Nascimento",
  },
];

/* export const DATA_PROPERTY: IProperty[] = [
  {
    id: 1,
    created_at: new Date(),
    responsible: "João Marcus",
    name: "ValesVerdes pecuária",
    email: "Frigorificominas@cria.digital",
    phone: "051984665588",
    uf: "MG",
    cep: "31005-200;",
    address: "Contria",
    address_number: "76",
    address_complement: "",
    city: "Corinto",
    qualified: "qualified",
  },
  {
    id: 2,
    created_at: new Date(),
    responsible: "João Marcus",
    name: "ValesVerdes pecuária",
    email: "Frigorificominas@cria.digital",
    phone: "051984665588",
    uf: "MG",
    cep: "31005-200;",
    address: "Contria",
    address_number: "76",
    address_complement: "",
    city: "Corinto",
    qualified: "notqualified",
  },
  {
    id: 3,
    created_at: new Date(),
    responsible: "João Marcus",
    name: "ValesVerdes pecuária",
    email: "Frigorificominas@cria.digital",
    phone: "051984665588",
    uf: "MG",
    cep: "31005-200;",
    address: "Contria",
    address_number: "76",
    address_complement: "",
    city: "Corinto",
    qualified: "block",
  },
  {
    id: 4,
    created_at: new Date(),
    responsible: "João Marcus",
    name: "ValesVerdes pecuária",
    email: "Frigorificominas@cria.digital",
    phone: "051984665588",
    uf: "MG",
    cep: "31005-200;",
    address: "Contria",
    address_number: "76",
    address_complement: "",
    city: "Corinto",
    qualified: "notqualified",
  },
  {
    id: 5,
    created_at: new Date(),
    responsible: "João Marcus",
    name: "ValesVerdes pecuária",
    email: "Frigorificominas@cria.digital",
    phone: "051984665588",
    uf: "MG",
    cep: "31005-200;",
    address: "Contria",
    address_number: "76",
    address_complement: "",
    city: "Corinto",
    qualified: "notqualified",
  },
]; */

/* export const DATA_OFFER: IOffers[] = [
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    status: true,
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Lorem ipsum",
    weight: 16.15,
    kgPrice: 8.45,
    status: true,
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Lorem ipsum",
    weight: 16.15,
    kgPrice: 8.45,
    status: true,
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Lorem ipsum",
    weight: 16.15,
    kgPrice: 8.45,
    status: false,
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Lorem ipsum",
    weight: 16.15,
    kgPrice: 8.45,
    status: false,
  },
]; */
export const DATA_ANIMALS = [
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 1,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 2,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Macho",
    weight: 58,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 3,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 4,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Macho",
    weight: 54,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 5,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 6,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 7,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Macho",
    weight: 58,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 8,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Fêmea",
    weight: 63,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 0 + 9,
  },
  {
    name: "Vales Verdes",
    category: "Lorem ipsum",
    email: "valesverders@cria.digital.com",
    gender: "Macho",
    weight: 54,
    birthday: new Date("2023-06-12T20:11:23.073Z"),
    race: "Loren ipsum lorem",
    teeth: "Loren ipsum",
    registerName: "Loren ipsum",
    registerNumber: 10,
  },
];
/* export const DATA_HISTORY = [
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    date: new Date("2023-06-12T20:11:23.073Z"),
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    date: new Date("2023-06-12T20:11:23.073Z"),
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    date: new Date("2023-06-12T20:11:23.073Z"),
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    date: new Date("2023-06-12T20:11:23.073Z"),
  },
  {
    responsible: "João Marcus",
    email: "valesverders@cria.digital.com",
    phone: "(031) 9 9999 9999",
    name: "Vales Verdes",
    adress: "Contria",
    adress_number: 76,
    uf: "MG",
    city: "Corinto",
    quantityAnimals: 345,
    category: "Categoria",
    weight: 16.15,
    kgPrice: 8.45,
    date: new Date("2023-06-12T20:11:23.073Z"),
  },
]; */

export const DATA_SLAUGHTER_REVENUE = [
  {
    month: "Janeiro de 2023",
    slaughtereAnimals: 348,
    totalProducers: 0o5,
    totalPaidValue: "R$105.450,00",
    mediumKgValue: "R$8,90",
  },
  {
    month: "Janeiro de 2023",
    slaughtereAnimals: 348,
    totalProducers: 0o5,
    totalPaidValue: "R$105.450,00",
    mediumKgValue: "R$8,90",
  },
  {
    month: "Janeiro de 2023",
    slaughtereAnimals: 348,
    totalProducers: 0o5,
    totalPaidValue: "R$105.450,00",
    mediumKgValue: "R$8,90",
  },
  {
    month: "Janeiro de 2023",
    slaughtereAnimals: 348,
    totalProducers: 0o5,
    totalPaidValue: "R$105.450,00",
    mediumKgValue: "R$8,90",
  },
  {
    month: "Janeiro de 2023",
    slaughtereAnimals: 348,
    totalProducers: 0o5,
    totalPaidValue: "R$105.450,00",
    mediumKgValue: "R$8,90",
  },
];

export const CATEGORIES = [
  {
    name: "Caprino",
    value: "Caprino",
    label: "Caprino",
  }, {
    name: "Ovino",
    value: "Ovino",
    label: "Ovino",
  },
]

/* export const CATEGORIES = [
  {
    name: "Cabras em Estação de Monta",
    value: "Cabras em Estação de Monta",
    label: "Cabras em Estação de Monta",
  },
  {
    name: "Cabras em Lactação",
    value: "Cabras em Lactação",
    label: "Cabras em Lactação",
  },
  { name: "Cabras Prenhas", value: "Cabras Prenhas", label: "Cabras Prenhas" },
  {
    name: "Cabritos Desmame",
    value: "Cabritos Desmame",
    label: "Cabritos Desmame",
  },
  {
    name: "Cabritos + 30 Dias",
    value: "Cabritos + 30 Dias",
    label: "Cabritos + 30 Dias",
  },
  { name: "Confinamento 1", value: "Confinamento 1", label: "Confinamento 1" },
  { name: "Confinamento 2", value: "Confinamento 2", label: "Confinamento 2" },
  { name: "Cria", value: "Cria", label: "Cria" },
  { name: "Reprodutores", value: "Reprodutores", label: "Reprodutores" },
  { name: "Recém Nascidos", value: "Recém Nascidos", label: "Recém Nascidos" },
  { name: "Recria 1", value: "Recria 1", label: "Recria 1" },
  { name: "Recria 2", value: "Recria 2", label: "Recria 2" },
  { name: "Pré-Parto", value: "Pré-Parto", label: "Pré-Parto" },
  { name: "Pós-Parto", value: "Pós-Parto", label: "Pós-Parto" },
  {
    name: "Marrãs em Estação de Monta",
    value: "Marrãs em Estação de Monta",
    label: "Marrãs em Estação de Monta",
  },
  { name: "Matrizes", value: "Matrizes", label: "Matrizes" },
  { name: "Venda", value: "Venda", label: "Venda" },
];
 */