import { Button, Flex, Text, TextInput, useMantineTheme } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import ListOfferDetails from "./ListOfferDetails";
import {
  BreadCrumbs,
  DivFather,
  ModalOptions,
  Tag,
} from "../../../../../components";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { putOffer, useOfferByid } from "../../../../../hooks";
import { queryClient } from "../../../../../constants/queryClients";

const OfferDetails: React.FC = () => {
  const {
    state: { animals: objAnimals, item: itemBreacrump, farm: farm },
  } = useLocation();
  const theme = useMantineTheme();
  const { data: accept } = useOfferByid(objAnimals._id);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [animals, setAnimais] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [typeModal, setTypeModal] = useState<string>("");

  const routes = [
    { title: "Ordens de compra", href: "/" },
    { title: "ofertas", href: "/demandas/ofertas" },
    { title: "Detalhes da oferta" },
  ];

  useEffect(() => {
    if (objAnimals && objAnimals?.animals) {
      const lowercasedSearch = search.toLocaleLowerCase();
      const animalsFilter = objAnimals?.animals.filter((animal: any) =>
        animal.name.toLocaleLowerCase().includes(lowercasedSearch)
      );
      setAnimais(animalsFilter);
    }
  }, [objAnimals, search]);

  const statusMutate = () => {
    changeStatus();
  };

  const { mutate: mutateSuspend, isLoading: loadingStatus } = useMutation(
    putOffer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["offers"]);
      },
      onError: () => {
        alert("Erro");
      },
    }
  );

  const changeStatus = () => {
    let obj = Object.assign({
      ...objAnimals,
      is_accepted: typeModal === "aceitar" ? "true" : "false",
    });
    mutateSuspend(obj);
  };

  const notNull =
    accept?.is_accepted === null || accept?.is_accepted === "Sim"
      ? true
      : false;

  return (
    <DivFather>
      <ModalOptions
        loading={loadingStatus}
        openModal={openModal}
        textStepTwo={"oferta"}
        setOpenModal={() => setOpenModal(!openModal)}
        typeModal={typeModal}
        onClick={() => {
          statusMutate();
        }}
      />
      <Flex align={"flex-start"} p={0} m={0}>
        <BreadCrumbs
          item={routes}
          state={{
            item: itemBreacrump,
          }}
        />
      </Flex>
      <Flex gap={24} align={"center"} justify={"space-between"}>
        <TextInput
          type="search"
          w="100%"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Pesquisar animal"
          icon={<IconSearch color="#BFC0C2" size={18} />}
        />
        {!notNull ? (
          <Tag
            w={"179px"}
            h={"54px"}
            ta={"center"}
            title={
              accept?.is_accepted === "false"
                ? "Oferta recusada"
                : "Oferta aceita"
            }
            status={accept?.is_accepted === "false" ? "red" : "green"}
          />
        ) : (
          <>
            <Button
              w={"155px"}
              h={"40px"}
              bg={theme.colors.alerts[2]}
              sx={{
                ":hover": {
                  background: theme.colors.alertsOpacity[1],
                },
              }}
              onClick={() => {
                setTypeModal("aceitar");
                setOpenModal(!openModal);
              }}
            >
              Aceitar oferta
            </Button>
            <Button
              w={"155px"}
              h={"40px"}
              bg={"transparent"}
              sx={{
                ":hover": {
                  background: theme.colors.alertsOpacity[0],
                },
                border: "1px solid" + theme.colors.alerts[0],
                color: theme.colors.alerts[0],
              }}
              onClick={() => {
                setTypeModal("recusar");
                setOpenModal(!openModal);
              }}
            >
              Recusar oferta
            </Button>
          </>
        )}
      </Flex>
      <Flex direction={"column"} justify={"flex-end"} mt={"32px"}>
        <Text fs={"18px"} ff={"poppins"} lh={"24px"} fw={400} color="#BFC0C2">
          {animals?.length} animais
        </Text>
      </Flex>
      <Flex direction={"column"}>
        <ListOfferDetails data={animals} farm={farm} />
      </Flex>
    </DivFather>
  );
};
export default OfferDetails;
