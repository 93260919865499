import { BackgroundImage, Button, Center, Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import { error404 } from "../../assets";

const NotFound: React.FC = () => {
  return (
    <Center w="80%" mx="auto">
      <BackgroundImage src={error404}>
        <Flex h="100vh" justify={"center"} align={"end"} pb={100}>
          <Button
            component={Link}
            to="/"
            variant="primary"
            maw={{ base: 348, 991: 576 }}
            w="100%"
          >
            Voltar para Home
          </Button>
        </Flex>
      </BackgroundImage>
    </Center>
  );
};

export default NotFound;
