import React from "react";
import { Flex, Text, useMantineTheme, Box } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import { ISlaughterRevenue } from "../../../../interfaces/ISlaughterRevenue";
import { date } from "../../../../utils/date";

interface IProps {
  data: ISlaughterRevenue[];
}

const ListSlaughterRevenue: React.FC<IProps> = ({ data }) => {
  const theme = useMantineTheme();

  return (
    <>
      {data.map((item: ISlaughterRevenue, index: number) => (
        <Flex
          key={index}
          direction={"column"}
          h={"80px"}
          m={"32px 0px"}
          sx={{ borderBottom: "1px solid" + theme.colors.gray[1] }}
        >
          <Flex
            justify={"space-between"}
            p={"0px 8px"}
            h={"80px"}
            align={"center"}
          >
            <Flex direction={"column"}>
              <Text fs={"18px"} fw={500} color={theme.colors.dark[0]}>
                {item.month}
              </Text>
              <Flex align={"center"} mt={"10px"}>
                <IconCalendar size={"16px"} color={theme.colors.gray[2]} />
                <Text
                  ml={"13px"}
                  fs={"14px"}
                  fw={300}
                  color={theme.colors.gray[2]}
                >
                  Mês Vigente
                </Text>
              </Flex>
            </Flex>
            <Flex direction={"column"}>
              <Text fs={"18px"} fw={400}>
                {item.slaughtereAnimals}
              </Text>
              <Text fs={"14px"} fw={300}>
                Animais Abatidos
              </Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fs={"18px"} fw={400}>
                {item.totalProducers < 10
                  ? "0" + item.totalProducers
                  : item.totalProducers}
              </Text>
              <Text fs={"14px"} fw={300}>
                Total de Produtores
              </Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fs={"18px"} fw={400}>
                {item.totalPaidValue}
              </Text>
              <Text fs={"14px"} fw={300}>
                Valor total Pago
              </Text>
            </Flex>
            <Flex direction={"column"}>
              <Text fs={"18px"} fw={400}>
                {item.mediumKgValue}
              </Text>
              <Text fs={"14px"} fw={300}>
                Valor Médio por kg
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </>
  );
};
export default ListSlaughterRevenue;
