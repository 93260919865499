import { Select } from "@mantine/core";
import { useEffect } from "react";
import { IconChevronDown } from "@tabler/icons-react";
import { getCities, getStates } from "../../resources/resource";

interface IProps {
  valueUf: string | undefined | null;
  setValueUf: any;
  valueCity: string | undefined | null;
  setValueCity: any;
}

const SelectLocale: React.FC<IProps> = ({
  setValueCity,
  setValueUf,
  valueCity,
  valueUf,
}) => {
  const states = getStates()

  useEffect(() => {
    if (!valueUf) {
      setValueCity(undefined);
    }
  }, [setValueCity, valueUf]);

  const cities = getCities(valueUf)

  return (
    <>
      <Select
        searchable
        clearable
        w="100%"
        miw={{ base: 241 }}
        value={valueUf}
        onChange={setValueUf}
        placeholder="Estado"
        rightSection={!valueUf ? <IconChevronDown size="1rem" /> : null}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
        data={states}
      />
      <Select
        searchable
        clearable={true}
        value={!!valueCity ? valueCity : null}
        w="100%"
        miw={{ base: 221 }}
        onChange={setValueCity}
        placeholder="Cidade"
        rightSection={!valueCity ? <IconChevronDown size="1rem" /> : null}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: "none" } }}
        data={cities}
      />
    </>
  );
};

export default SelectLocale;
