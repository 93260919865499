export function convertPhone(numero: string) {
  if (String(numero)) {
    let n = String(numero);
    let pt1 = n.substring(4, 6);
    let pt2 = n.substring(7, 8);
    let pt3 = n.substring(8, 12);
    let pt4 = n.substring(12, 17);

    return `(0${pt1}) ${pt2} ${pt3} ${pt4}`;
  } else {
    return String(numero);
  }
}
