import React from "react";
import { IDetails } from "../../../../../interfaces/IDetails";
import { Box, Flex, Text, useMantineTheme } from "@mantine/core";
import { IconAt, IconGenderFemale, IconGenderMale } from "@tabler/icons-react";
import { date } from "../../../../../utils/date";

interface IProps {
  data?: any;
}

const ListHistoryDetails: React.FC<IProps> = ({ data }) => {
  const theme = useMantineTheme();
  return (
    <>
      {data?.map((item: any, index: number) => {
        return (
          <Flex
            key={index}
            w={"1,740px"}
            h={"110px"}
            mt={"26px"}
            mb={"16px"}
            align={"center"}
            direction={"column"}
          >
            <Box
              w={"100%"}
              h={"140px"}
              style={{
                borderBottom: "1px solid" + theme.colors.gray[1],
                textDecoration: "none",
              }}
            >
              <Flex
                w={"100%"}
                h={"100%"}
                justify={"space-between"}
                align={"center"}
                mb={"29px"}
                p={"0px 8px"}
              >
                <Flex align={"center"} w="30%" maw={250}>
                  <Box>
                    <Text
                      fs={"14px"}
                      ff={"Poppins"}
                      color="#707072"
                      fw={500}
                      lh={"24px"}
                    >
                      {item?.name}
                    </Text>
                    <Flex align={"center"}>
                      <IconAt color="#707072" size={"16px"} />
                      <Text
                        fs={"14px"}
                        ff={"Poppins"}
                        color="#2E72E9"
                        fw={300}
                        lh={"24px"}
                        ml={"15px"}
                      >
                        {item?.email}
                      </Text>
                    </Flex>
                    <Flex align={"center"}>
                      {item.gender === "Macho" ? (
                        <IconGenderMale color="#707072" size={"16px"} />
                      ) : (
                        <IconGenderFemale color="#707072" size={"16px"} />
                      )}

                      <Text
                        fs={"14px"}
                        ff={"Poppins"}
                        color="#707072"
                        fw={300}
                        lh={"24px"}
                        ml={"15px"}
                      >
                        {item?.gender}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
                <Box miw={200} w="20%">
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {!!item?.weight?.weight?.animal_weight
                      ? `${item?.weight?.weight?.animal_weight} Kg`
                      : "Nenhum peso registrado"}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Peso
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {date(item.birthday)}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Nascimento
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item.race}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Raça
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.category}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Categoria
                  </Text>
                </Box>
                {/* <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.register_name}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Nome de registro
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item.register_number < 10
                      ? "0" + item.register_number
                      : item.register_number}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    N° de registro
                  </Text>
                </Box> */}
              </Flex>
            </Box>
          </Flex>
        );
      })}
    </>
  );
};
export default ListHistoryDetails;
