import React, { useState } from "react";
import { Box, Button, Flex, Text, useMantineTheme } from "@mantine/core";
import { IDemands, IDemandsData } from "../../../../interfaces/IDemands";
import { IconArrowRight } from "@tabler/icons-react";
import {
  ModalSuccess,
  ModalOptions,
  Tag,
  PopoverOptions,
} from "../../../../components";
import FormDemands from "../FormDemands";
import { date } from "../../../../utils/date";
import { NavLink } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { deleteDemands, putDemands } from "../../../../hooks";
import { queryClient } from "../../../../constants/queryClients";

interface Iprops {
  data?: IDemands;
}
const ListDemands: React.FC<Iprops> = ({ data }) => {
  const theme = useMantineTheme();
  const today = new Date();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [typeModal, setTypeModal] = useState<string>("");
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  //const [typeModalForm, setTypeModalForm] = useState<string>("");
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const [option, setOption] = useState<string>("");
  const [typeModalSuccess, setTypeModalSuccess] = useState<string>("");

  const edit = () => {
    setOpenModalForm(!openModalForm);
  };

  const destroy = () => {
    setOpenModal(!openModal);
    setTypeModal("excluir");
  };

  const { mutate: mutateDelete, isLoading: loadingDel } = useMutation(
    deleteDemands,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["demands"]);
      },
      onError: () => {},
    }
  );

  const { mutate: mutateSuspend, isLoading: loadingStatus } = useMutation(
    putDemands,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["demands"]);
      },
      onError: () => {
        alert("Erro");
      },
    }
  );

  const changeSuspend = () => {
    let obj = Object.assign({
      ...item,
      status: !item?.status,
    });
    setTypeModal(item?.status ? "suspensa" : "ativa");
    mutateSuspend(obj);
  };

  const dateCompare = (open: Date, close: Date) => {
    const dateOne = new Date(open);
    dateOne.setHours(0, 0, 0, 0);
    const dateTwo = new Date(close);
    dateTwo.setHours(23, 59, 59, 999);

    if (dateOne > today || dateTwo < today) {
      return true;
    }
    return false;
  };

  const dateType = (open: Date, close: Date) => {
    const dateOne = new Date(open);
    dateOne.setHours(0, 0, 0, 0);
    const dateTwo = new Date(close);
    dateTwo.setHours(23, 59, 59, 999);
    if (dateOne > today) {
      return "Demanda ainda não abriu";
    }
    return "Demanda vencida";
  };

  return (
    <>
      <ModalSuccess
        openModal={openModalSuccess}
        setOpenModal={() => setOpenModalSuccess(!openModalSuccess)}
        typeModal={typeModalSuccess}
      />
      <ModalOptions
        loading={loadingStatus || loadingDel}
        openModal={openModal}
        textStepTwo={"demanda"}
        setOpenModal={() => setOpenModal(!openModal)}
        typeModal={typeModal}
        onClick={() => {
          if (option === "delete") {
            mutateDelete(item);
          } else {
            changeSuspend();
          }
        }}
      />

      <FormDemands
        state={item?.city?._id}
        openModal={openModalForm}
        setOpenModal={() => setOpenModalForm(!openModalForm)}
        item={item}
      />
      {data?.demands?.map((item: IDemandsData, index: number) => {
        const open = new Date(item?.opening);
        const close = new Date(item?.closing);
        return (
          <Flex
            key={index}
            align={"center"}
            direction={"column"}
            sx={{
              borderTop: "1px solid transparent",
              ":hover": {
                animation: "ease-in",
                animationName: "ease-in",
                animationDuration: "30000ms",
                backgroundColor: theme.colors.gray[0],
                borderColor: theme.colors.gray[1],
              },
            }}
          >
            <Box
              h="136px"
              w={"100%"}
              mx={"0px"}
              p={"0px"}
              style={{
                borderBottom: "1px solid" + theme.colors.gray[1],
                textDecoration: "none",
              }}
              sx={{
                ":hover": {
                  animation: "ease-in",
                  animationName: "ease-in",
                  animationDuration: "30000ms",
                  backgroundColor: theme.colors.gray[0],
                },
              }}
            >
              <Flex
                w={"100%"}
                h={"100%"}
                justify={"space-between"}
                align={"center"}
                mb={"29px"}
                p={"0px 8px"}
              >
                <Box miw={250}>
                  <Text
                    size={"24px"}
                    ff={"Poppins"}
                    color="primary.2"
                    fw={500}
                    //lh={"24px"}
                  >
                    {item?.title}
                  </Text>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item?.category}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    <b style={{ fontWeight: 500 }}>ID:</b> {item?._id}
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item.quantity_of_animals}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Animais
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {item.value.toLocaleString("pt-BR", {
                      currency: "BRL",
                      style: "currency",
                    })}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Valor por kg
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {date(item.opening)}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Abertura
                  </Text>
                </Box>
                <Box>
                  <Text
                    fs={"18px"}
                    ff={"Poppins"}
                    color="#303030"
                    fw={500}
                    lh={"24px"}
                  >
                    {date(item.closing)}
                  </Text>
                  <Text
                    fs={"14px"}
                    ff={"Poppins"}
                    color={theme.colors.gray[2]}
                    fw={300}
                  >
                    Encerramento
                  </Text>
                </Box>
                <Tag
                  status={
                    item.status === false ||
                    dateCompare(item?.opening, item?.closing)
                      ? "gray"
                      : "green"
                  }
                  w={"158px"}
                  h={"52px"}
                  ta={"center"}
                  title={
                    dateCompare(item?.opening, item?.closing)
                      ? "Demanda fora do prazo"
                      : item.status === true
                      ? "Demanda em aberto"
                      : "Demanda finalizada"
                  }
                />
                <Flex gap="40px" align="center">
                  <PopoverOptions
                    btns={[
                      dateCompare(item?.opening, item?.closing)
                        ? ""
                        : !item?.status
                        ? "Desbloquear"
                        : "Suspender",
                    ]}
                    block={item.status ? "true" : "false"}
                    editItem={() => {
                      setItem(item);
                      edit();
                    }}
                    deleteItem={() => {
                      setItem(item);
                      destroy();
                      setOption("delete");
                    }}
                    setStatus={() => {
                      setOpenModal(!openModal);
                      setItem(item);
                    }}
                    setTypeModal={setTypeModal}
                  />
                  <Button
                    component={NavLink}
                    disabled={dateCompare(item?.opening, item?.closing)}
                    to="/demandas/ofertas"
                    state={{
                      item: item,
                    }}
                    color={theme.colors.primary[0]}
                    bg={"transparent"}
                    sx={{
                      ":hover": {
                        backgroundColor: theme.colors.primary[0],
                      },
                      /*   "&[data-disabled]": {
                        backgroundColor: theme.colors.gray[0],
                      }, */
                    }}
                  >
                    <IconArrowRight color={theme.colors.dark[0]} />
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        );
      })}
    </>
  );
};

export default ListDemands;
