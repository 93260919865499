import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import {
    IconAlertTriangle,
    IconCircleCheck,
    IconXboxX,
} from "@tabler/icons-react";

interface passwordIProps {
    passwordOne: string;
    passwordTwo?: string;
}

const PasswordRequirements: React.FC<passwordIProps> = ({
    passwordOne,
    passwordTwo,
}) => {
    const isNumeric = (text: string): boolean => /\d+/.test(text);
    const hasLowerCase = (text: string): boolean => /[a-z]/.test(text);
    const hasUpperCase = (text: string): boolean => /[A-Z]/.test(text);
    const isLong = (text: string): boolean => text.length >= 8;
    //   const charSpecial = (text: string): boolean => /[^a-zA-Z 0-9]+/g.test(text);

    return (
        <Box>
            <Text color="#6B6B6B" fs="14px" mb={14}>
                A senha deve possuir:
            </Text>
            {passwordOne ? (
                <Flex
                    direction="row"
                    gap={"10px 26px"}
                    wrap="wrap"
                    sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                    }}
                >
                    <Flex direction="row" align="center" gap={6} >
                        {isNumeric(passwordOne) ? (
                            <IconCircleCheck size="20" color="green" />
                        ) : (
                            <IconAlertTriangle size="20" color="#DF0000" />
                        )}
                        <Text color="#161616">Contém número</Text>
                    </Flex>
                    <Flex direction="row" align="center" gap={6} >
                        {hasLowerCase(passwordOne) ? (
                            <IconCircleCheck size="20" color="green" />
                        ) : (
                            <IconAlertTriangle size="20" color="#DF0000" />
                        )}
                        <Text color="#161616">Contém letra minúscula</Text>
                    </Flex>
                    <Flex direction="row" align="center" gap={6} >
                        {hasUpperCase(passwordOne) ? (
                            <IconCircleCheck size="20" color="green" />
                        ) : (
                            <IconAlertTriangle size="20" color="#DF0000" />
                        )}
                        <Text color="#161616">Contém letra maiúscula</Text>
                    </Flex>

                    <Flex direction="row" align="center" gap={6}>
                        {isLong(passwordOne) ? (
                            <IconCircleCheck size="20" color="green" />
                        ) : (
                            <IconAlertTriangle size="20" color="#DF0000" />
                        )}
                        <Text color="#161616">Contém 8 caracteres</Text>
                    </Flex>
                </Flex>
            ) : (
                <Flex
                    direction="row"
                    gap={10}
                    wrap="wrap"
                    sx={{
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                    }}
                >
                    <Flex direction="row" align="center" gap={6} >
                        <IconXboxX size="20" color="#868686" />
                        <Text color="#161616">Contém número</Text>
                    </Flex>
                    <Flex direction="row" align="center" gap={6} >
                        <IconXboxX size="20" color="#868686" />
                        <Text color="#161616">Contém letra maiúscula</Text>
                    </Flex>
                    <Flex direction="row" align="center" gap={6} >
                        <IconXboxX size="20" color="#868686" />
                        <Text color="#161616">Contém letra minúscula</Text>
                    </Flex>

                    <Flex direction="row" align="center" gap={6}>
                        <IconXboxX size="20" color="#868686" />
                        <Text color="#161616">Contém 8 caracteres</Text>
                    </Flex>
                </Flex>
            )}
        </Box>
    );
};

export default PasswordRequirements;
