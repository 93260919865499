import React from "react";
import {
  Flex,
  Text,
  Input,
  useMantineTheme,
  Button,
  Pagination,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import ListSlaughterRevenue from "./ListSlaughterRevenue";
import { DATA_SLAUGHTER_REVENUE } from "../../../constants/contants";
import { DivFather } from "../../../components";

const SlaughterRevenue: React.FC = () => {
  const theme = useMantineTheme();
  return (
    <DivFather>
      <Text fs={"16px"} fw={500} lh={"24px"} mb={"24px"}>
        Receita dos Abates
      </Text>
      <Flex justify={"space-between"} align={"center"} gap={40}>
        <Input
          w={"1541px"}
          h={"54px"}
          placeholder="Pesquisar mês"
          icon={<IconSearch color={theme.colors.gray[1]} size={"18px"} />}
        />
        <Button
          w={"167px"}
          h={"40px"}
          ta={"center"}
          bg={theme.colors.primary[1]}
          sx={{
            ":hover": {
              background: theme.colors.primary[0],
            },
          }}
        >
          Exportar Dados
        </Button>
      </Flex>
      <ListSlaughterRevenue data={DATA_SLAUGHTER_REVENUE} />
      <Flex justify={"space-between"} h={"48px"} mt={"85px"}>
        <Flex justify={"flex-end"} direction={"column"}>
          <Text fs={"18px"} fw={400} m={0} p={0}>
            {DATA_SLAUGHTER_REVENUE.length} Propriedades cadastrados
          </Text>
        </Flex>
        <Pagination total={999} />
      </Flex>
    </DivFather>
  );
};
export default SlaughterRevenue;
