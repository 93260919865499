import axios from "axios";

const api = axios.create({
  baseURL: "https://cl6zu07o7f.execute-api.sa-east-1.amazonaws.com/dev",
  timeout: 30000,
});

api.interceptors.request.use(async (config) => {
  const token = window.localStorage.getItem("@bodetech:token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = window.localStorage.getItem("@bodetech:refreshToken");

        if (!refreshToken) {
          localStorage.clear();
          window.location.replace("/");
          throw new Error("Refresh token not available");
        }

        const response = await api.post("/refresh-token", {
          refresh_token: refreshToken,
        });

        const newToken = response.data.token;

        window.localStorage.setItem("@bodetech:token", newToken);

        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

        return api(originalRequest);
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        localStorage.clear();
        window.location.replace("/");
        return Promise.reject(refreshError);
      }
    }

    if (error.response?.status === 401) {
      localStorage.clear();
    }

    console.error("Unhandled interception error:", error);
    return Promise.reject(error);
  }
);

export { api };
