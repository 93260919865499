import { Flex, Text } from "@mantine/core";

interface IProps {
  children: any;
  title?: string;
}

const DivFather: React.FC<IProps> = ({ children, title }) => {
  return (
    <Flex px={{ base: 20 }} justify="center" w="100%" mt={38} mih="calc(100vh - 140px)">
      <Flex
        mx={{ base: 20 }}
        maw={1680}
        w="100%"
        direction="column"
      >
        {!!title ? (
          <Text mb={24} fs={"16px"} fw={500} lh={"24px"}>
            {title}
          </Text>
        ) : null}
        {children}
      </Flex>
    </Flex>
  );
};

export default DivFather;
